export const callStatus = {
  LIST_PENDING: 'LIST_PENDING',
  LIST_IN_PROCESS: 'LIST_IN_PROCESS',
  LIST_FAILED: 'LIST_FAILED',
  LIST_SUCCESS: 'LIST_SUCCESS',
  LIST_REMOVE: 'LIST_REMOVE',
  LIST_ADD: 'LIST_ADD',
  LIST_UPDATE: 'LIST_UPDATE',
  LIST_DELETED_PENDING: 'LIST_DELETED_PENDING',
  LIST_DELETED_IN_PROCESS: 'LIST_DELETED_IN_PROCESS',
  LIST_DELETED_FAILED: 'LIST_DELETED_FAILED',
  LIST_DELETED_SUCCESS: 'LIST_DELETED_SUCCESS',
  OBJECT_PENDING: 'OBJECT_PENDING',
  OBJECT_IN_PROCESS: 'OBJECT_IN_PROCESS',
  OBJECT_FAILED: 'OBJECT_FAILED',
  OBJECT_SUCCESS: 'OBJECT_SUCCESS',
  PENDING: 'PENDING',
  IN_PROCESS: 'IN_PROCESS',
  FAILED: 'FAILED',
  SUCCESS: 'SUCCESS',
  OBJECT_SAVE_SUCCESS: 'OBJECT_SAVE_SUCCESS',
};

export const columnsAlicuotaPrint = [
  { index: 'alicuota', title: 'Alicuota' },

  { index: 'base_imponible', title: 'Base Imponible' },
  { index: 'importe', title: 'Importe' },
];

export const columnsPrint = {
  '-2': [
    { index: 'descripcion', title: 'Descripción', width: '200px' },
    { index: 'precio', title: 'Precio', width: '90px' },
    { index: 'cantidad', title: 'Cantidad', width: '50px' },
    { index: 'subtotal', title: 'Subtotal', width: '110px' },
  ],
  '-3': [
    { index: 'descripcion', title: 'Descripción', width: '200px' },
    { index: 'cantidad', title: 'Cantidad', width: '50px' },
  ],
  '-5': [
    { index: 'descripcion', title: 'Descripción', width: '160px' },
    { index: 'precio', title: 'Precio', width: '90px' },
    { index: 'descuento', title: 'Bonif', width: '50px' },
    { index: 'cantidad', title: 'Cantidad', width: '50px' },
    { index: 'subtotal', title: 'Subtotal', width: '100px' },
  ],
  1: [
    { index: 'descripcion', title: 'Descripción', width: '160px' },
    { index: 'precio', title: 'Precio', width: '90px' },
    { index: 'descuento', title: 'Bonif', width: '50px' },
    { index: 'cantidad', title: 'Cantidad', width: '50px' },
    { index: 'alicuota', title: 'IVA', width: '50px' },
    { index: 'subtotal', title: 'Subtotal', width: '100px' },
  ],
  6: [
    { index: 'descripcion', title: 'Descripción', width: '160px' },
    { index: 'precio', title: 'Precio', width: '90px' },
    { index: 'descuento', title: 'Bonif', width: '50px' },
    { index: 'cantidad', title: 'Cantidad', width: '50px' },
    { index: 'subtotal', title: 'Subtotal', width: '100px' },
  ],
  '-6': [
    { index: 'descripcion', title: 'Descripción', width: '200px' },
    { index: 'precio', title: 'Precio', width: '90px' },
    { index: 'cantidad', title: 'Cantidad', width: '50px' },
    { index: 'subtotal', title: 'Subtotal', width: '110px' },
  ],
  3: [
    { index: 'descripcion', title: 'Descripción', width: '200px' },
    { index: 'precio', title: 'Precio', width: '90px' },
    { index: 'cantidad', title: 'Cantidad', width: '50px' },
    { index: 'alicuota', title: 'IVA', width: '50px' },
    { index: 'subtotal', title: 'Subtotal', width: '110px' },
  ],
  8: [
    { index: 'descripcion', title: 'Descripción', width: '200px' },
    { index: 'precio', title: 'Precio', width: '90px' },
    { index: 'cantidad', title: 'Cantidad', width: '50px' },
    { index: 'subtotal', title: 'Subtotal', width: '110px' },
  ],
};

export const columnsCobroComprobantesPrint = [
  { index: 'comprobante_tipo_nombre', title: 'Tipo', width: '150px' },
  { index: 'comprobante_numero', title: 'Número', width: '150px' },
  { index: 'comprobante_fecha', title: 'Fecha', width: '150px' },
  { index: 'comprobante_total', title: 'Total', width: '150px' },
  { index: 'comprobante_pendiente', title: 'Pendiente', width: '150px' },
  { index: 'total', title: 'Importe', width: '150px' },
];

export const columnsCobroMediosPrint = [
  { index: 'tipo', title: 'Tipo', width: '100px' },
  { index: 'detalle', title: 'Detalle', width: '100px' },
  { index: 'importe', title: 'Importe', width: '100px' },
];

export const columnsCtaCtePrint = [
  { index: 'fecha', title: 'Fecha', width: '100px' },
  { index: 'numero', title: 'Número', width: '100px' },
  { index: 'tipo_nombre', title: 'Tipo', width: '100px' },
  { index: 'debe', title: 'Debe', width: '100px' },
  { index: 'haber', title: 'Haber', width: '100px' },
  { index: 'saldo', title: 'Saldo', width: '100px' },
];
