import React, { useState, useEffect } from 'react';
import { Button, Space } from 'antd';

import { useHistory } from 'react-router-dom';
import MetaTable from './base/MetaTable';
import { clienteApi } from '../utils/api';
import CustomCellRender from './base/CustomCellRender';

const ClienteDeudores = () => {
  const history = useHistory();

  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    clienteApi
      .get(`/deudores`)
      .then((response) => {
        setRows(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
    // eslint-disable-next-line
  }, []);

  // const handleImprimir = (data) => {};

  const handleCtaCte = (data) => {
    history.push(`/clientes/ctacte/${data.id}`);
  };

  const columns = [
    {
      width: '70%',
      ellipsis: true,
      title: 'Nombre/Razón Social',
      dataIndex: 'nombre',
      sorter: (a, b) => a.nombre.localeCompare(b.nombre),
      search: true,
    },
    // {
    //   width: 200,
    //   ellipsis: true,
    //   title: 'Email',
    //   dataIndex: 'email',
    // },
    // {
    //   width: 200,
    //   ellipsis: true,
    //   title: 'Teléfono',
    //   dataIndex: 'telefono',
    // },
    {
      width: 100,
      ellipsis: true,
      title: 'Saldo',
      dataIndex: 'saldo_final',
      render: (text) => (
        <CustomCellRender text={text} justify={'space-between'} prefix={'$'} />
      ),
    },
    {
      title: '',
      key: 'action',
      render: (text, record) => (
        <Space size="middle">
          {/* <Button onClick={(e) => handleImprimir(record)}>Exportar</Button> */}
          <Button onClick={(e) => handleCtaCte(record)}>CtaCte</Button>
        </Space>
      ),
      width: 100,
    },
  ];

  return (
    <MetaTable
      dataSource={rows}
      dataColumns={columns}
      bordered={false}
      selection={false}
      loading={loading}
      // cssStyle={true}
    />
  );
};

export default ClienteDeudores;
