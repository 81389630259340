import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import { columnsAlicuotaPrint } from '../../utils/constants';

// Create styles
const styles = StyleSheet.create({
  text: {
    marginBottom: 3,
    fontSize: 14,
  },
  tableHeader: {
    fontSize: 10,
  },
  tableData: {
    paddingLeft: 2,
    paddingRight: 2,
    fontSize: 10,
  },
});

const ComprobanteViewerAlicuotas = ({ data }) => {
  return (
    <>
      <View>
        <View style={{ flex: 1, flexDirection: 'column' }}>
          <View
            style={{
              flexDirection: 'row',
              border: 1,
              padding: 3,
            }}
          >
            {columnsAlicuotaPrint.map((col, idx) => {
              return (
                <Text
                  style={[
                    styles.tableHeader,
                    {
                      paddingLeft: '3px',
                      width: '100px',
                    },
                    col.index === 'importe' ? {} : { borderRight: 1 },
                  ]}
                  key={idx}
                >
                  {col.title}
                </Text>
              );
            })}
          </View>
          {data.alicuotas.map((item, idx) => (
            <View style={{ flexDirection: 'row', padding: 3 }} key={idx}>
              {columnsAlicuotaPrint.map((col, idx2) => {
                return (
                  <Text
                    style={[
                      styles.tableData,
                      { textAlign: 'right', width: '100px' },
                    ]}
                    key={idx2}
                  >
                    {col.index === 'alicuota'
                      ? `${item[col.index]}%`
                      : `$${item[col.index]}`}
                  </Text>
                );
              })}
            </View>
          ))}
        </View>
      </View>
    </>
  );
};
export default ComprobanteViewerAlicuotas;
