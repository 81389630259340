import { clienteContactoApi } from '../../utils/api';
import {
  getObjectDataAction,
  createObjectDataPending,
  getListDataAction,
  saveObjectDataAction,
  deleteObjectDataAction,
  createListDataDeletedPending,
  getListByParentDataAction,
} from '../../utils/DataAction';

export const get = (id) =>
  getObjectDataAction({
    dataName: 'CLIENTE_CONTACTO',
    api: clienteContactoApi,
    relUrl: id,
  });

export const getAll = getListDataAction({
  dataName: 'CLIENTE_CONTACTO',
  api: clienteContactoApi,
  relUrl: '',
});

export const getAllByParent = (id) =>
  getListByParentDataAction({
    dataName: 'CLIENTE_CONTACTO',
    api: clienteContactoApi,
    relUrl: `indexByParent/${id}`,
  });

export const save = (data) =>
  saveObjectDataAction({
    dataName: 'CLIENTE_CONTACTO',
    api: clienteContactoApi,
    relUrl: '',
    data: data,
  });

export const deleted = (id) =>
  deleteObjectDataAction({
    dataName: 'CLIENTE_CONTACTO',
    api: clienteContactoApi,
    relUrl: id,
  });

export const setPending = createObjectDataPending('CLIENTE_CONTACTO');
export const setDeletedPending = createListDataDeletedPending(
  'CLIENTE_CONTACTO'
);
