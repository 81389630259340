require('dotenv').config();

console.log('NODE_ENV: ' + process.env.NODE_ENV);
console.log('REACT_APP_VERSION: ' + process.env.REACT_APP_VERSION);
console.log('REACT_APP_API_URL: ' + process.env.REACT_APP_API_URL);
console.log('REACT_APP_API_TIMEOUT: ' + process.env.REACT_APP_API_TIMEOUT);

export const ENV = process.env.NODE_ENV; // development | test | production
export const VERSION = process.env.REACT_APP_VERSION;
export const API_URL = process.env.REACT_APP_API_URL;
export const API_TIMEOUT = process.env.REACT_APP_API_TIMEOUT;
