import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { callStatus } from '../utils/constants';
import { Spin, Select } from 'antd';
import { getAll, setPending } from '../redux/actions/productoActions';
import ProductoEditor from './ProductoEditor';
const { Option } = Select;

const ProductoBusqueda = ({ dispatch, stateData }) => {
  const [productoId, setProductoId] = useState(null);
  const [resultSearchProducto, setResultSearchProducto] = useState([]);
  const [productoList, setProductoList] = useState([]);

  useEffect(() => {
    getAll(dispatch);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setProductoList(stateData.list.data);
    // eslint-disable-next-line
  }, [stateData]);

  const onSearchProducto = (value) => {
    if (value) {
      let result = [];
      result = productoList.filter(
        (item) =>
          (item.nombre &&
            item.nombre.toLowerCase().includes(value.toLowerCase())) ||
          (item.codigo &&
            item.codigo.toLowerCase().includes(value.toLowerCase()))
      );

      setResultSearchProducto(result);
    } else {
      setResultSearchProducto([]);
    }
  };

  const onChangeProducto = (value) => {
    const result = productoList.find((item) => item.id === value);

    if (result) {
      setResultSearchProducto([{ ...result }]);
      setProductoId(result.id);
    } else {
      setProductoId(null);
      setResultSearchProducto([]);
    }
  };

  const handleHideEditor = () => {
    setProductoId(null);
    setResultSearchProducto([]);
    dispatch(setPending());
  };

  return (
    <>
      <Spin spinning={stateData.list.status !== callStatus.LIST_SUCCESS}>
        <Select
          style={{ width: '100%' }}
          showSearch
          defaultActiveFirstOption={false}
          showArrow={false}
          filterOption={false}
          onSearch={onSearchProducto}
          onChange={onChangeProducto}
          notFoundContent={null}
          allowClear
          value={productoId}
          // disabled={stateData.list.status !== callStatus.LIST_SUCCESS}
        >
          {resultSearchProducto.map((item, index) => (
            <Option key={index} value={item.id}>
              {item.codigo} - {item.nombre}
            </Option>
          ))}
        </Select>

        {productoId ? (
          <ProductoEditor
            id={productoId}
            onCancel={handleHideEditor}
            visible={true}
            tabActive={'3'}
          />
        ) : (
          false
        )}
      </Spin>
    </>
  );
};

const mapStateToProps = (state) => {
  const { producto } = state;
  const stateData = producto;
  return {
    stateData,
  };
};
export default connect(mapStateToProps)(ProductoBusqueda);
