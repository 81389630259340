import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { get } from '../../redux/actions/comprobanteActions';
import QRCode from 'qrcode.react';
import { Page, View, Document, PDFViewer } from '@react-pdf/renderer';
import { Modal } from 'antd';
import { callStatus } from '../../utils/constants';
import cloneDeep from 'lodash/cloneDeep';
import ComprobanteViewerWithoutItems from './ComprobanteViewerWithoutItems';
import ComprobanteViewerItems from './ComprobanteViewerItems';
import EncabezadoEmpresa from './EncabezadoEmpresa';
import Encabezado from './Encabezado';
import EncabezadoCliente from './EncabezadoCliente';
import PieAfip from './PieAfip';
import PieTotales from './PieTotales';
import ComprobanteObservacion from './ComprobanteObservacion';
import { getFieldConfig } from '../../utils/funtions';

const MyDocument = ({ stateData, qr, config }) => {
  let data = cloneDeep(stateData);

  if (data.tipo_id !== 1 && data.tipo_id !== 3) {
    data.items = data.items.map((item, idx) => {
      const precio = (
        parseFloat(item.precio) +
        (parseFloat(item.precio) * parseFloat(item.alicuota)) / 100
      ).toFixed(2);
      return { ...item, precio: precio };
    });
  }

  data.items = [
    ...data.items.map((item) => {
      if (item.parent_id) {
        return {
          ...item,
          precio: '',
          subtotal: '',
          descuento: '',
          alicuota: '',
          cantidad:
            data.tipo_id === -3 || data.tipo_id === -2 ? item.cantidad : '',
        };
      } else {
        return { ...item };
      }
    }),
  ];

  return (
    <Document size="A4">
      <Page style={{ padding: 15 }} wrap>
        <Encabezado data={data} qr={qr} type="comprobante" />

        <EncabezadoEmpresa data={config} />

        <EncabezadoCliente data={data} qr={qr} />

        <View style={{ flex: 1, flexDirection: 'column' }}>
          {data.items.length > 0 ? (
            <ComprobanteViewerItems data={data} />
          ) : (
            <ComprobanteViewerWithoutItems data={data} />
          )}
        </View>

        {data.items.length > 0 && data.observacion && (
          <ComprobanteObservacion data={data} />
        )}

        {data.tipo_id !== -3 && <PieTotales data={data} qr={qr} />}

        {qr && <PieAfip data={data} qr={qr} />}
      </Page>
    </Document>
  );
};

const ComprobanteViewer = ({
  dispatch,
  stateData,
  configData,
  id,
  visible,
  onCancel,
}) => {
  const [qrCodeData, setQrCodeData] = useState(null);
  const [qrCodeDataJSON, setQrCodeDataJSON] = useState(null);
  const [loadPDF, setLoadPDF] = useState(false);

  useEffect(() => {
    if (id) {
      dispatch(get(id));
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (
      stateData.object.status === callStatus.OBJECT_SUCCESS &&
      configData.list.status === callStatus.LIST_SUCCESS
    ) {
      if (stateData.object.data.tipo_id > 0 && stateData.object.data.cae) {
        //https://www.afip.gob.ar/fe/qr/especificaciones.asp
        const jsonData = {
          ver: 1,
          fecha: stateData.object.data.fecha,
          cuit: getFieldConfig(configData.list.data, 'afip_cuit'), //no lo esta reconociendo la app
          ptoVta: parseInt(stateData.object.data.punto_venta.nombre),
          tipoCmp: parseInt(stateData.object.data.id), //esto est mal
          nroCmp: parseInt(stateData.object.data.numero),
          importe: parseFloat(stateData.object.data.total),
          moneda: 'PES',
          ctz: 1,
          tipoDocRec: 80,
          nroDocRec: parseInt(stateData.object.data.cliente.cuit),
          tipoCodAut: 'E',
          codAut: parseInt(stateData.object.data.cae),
        };

        const objJsonStr = JSON.stringify(jsonData);
        setQrCodeDataJSON(Buffer.from(objJsonStr).toString('base64'));
      }

      setLoadPDF(true);
    }
  }, [stateData, configData]);

  useEffect(() => {
    if (qrCodeDataJSON) {
      const qrCodeCanvas = document.querySelector('canvas');
      const qrCodeDataUri = qrCodeCanvas.toDataURL('image/jpg', 0.3);
      setQrCodeData(qrCodeDataUri);
    }
  }, [qrCodeDataJSON]);

  return (
    <Modal
      centered
      maskClosable={false}
      visible={visible}
      title="Impresión Comprobante"
      onCancel={onCancel}
      width={700}
      bodyStyle={{ padding: 0, background: '#3a3d3f ' }}
      footer={null}
    >
      {qrCodeDataJSON && (
        <div style={{ visibility: 'collapse', height: 0 }}>
          <QRCode
            style={{ width: 150, height: 150 }}
            value={`https://www.afip.gob.ar/fe/qr/?p=${qrCodeDataJSON}`}
          />
        </div>
      )}
      {loadPDF && (
        <PDFViewer
          style={{
            width: '100%',
            height: '400px',
            border: 'none',
            margin: 0,
            padding: 0,
            marginTop: '-2px',
          }}
        >
          <MyDocument
            stateData={stateData.object.data}
            qr={qrCodeData}
            config={configData.list.data}
          />
        </PDFViewer>
      )}
    </Modal>
  );
};

const mapStateToProps = (state) => {
  const { comprobante, configuracion } = state;
  const stateData = comprobante;
  const configData = configuracion;
  return {
    stateData,
    configData,
  };
};
export default connect(mapStateToProps)(ComprobanteViewer);
