import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Modal, Form, Input, Row, Col, Select } from 'antd';
// import { get, save } from '../redux/actions/transferenciaActions';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { compareObject } from '../../utils/funtions';
import { getAll as depositos } from '../../redux/actions/depositoActions';

const { confirm } = Modal;

const ProductoDepositoEditor = ({
  dispatch,
  dataSource,
  stateData,
  visible,
  onCancel,
  onCreate,
  deposito,
  configuracion,
}) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (dataSource) {
      form.setFieldsValue(dataSource);
      setInitial(dataSource);
    }

    depositos(dispatch);
    // eslint-disable-next-line
  }, [dataSource]);

  useEffect(() => {
    setDepositosList(deposito.list.data);
    // eslint-disable-next-line
  }, [deposito]);

  const [initial, setInitial] = useState({});
  const [depositosList, setDepositosList] = useState([]);

  const handleOnCancel = () => {
    if (compareObject(initial, form.getFieldsValue())) {
      confirm({
        title: 'Posee cambios sin guardar, desea continuar?',
        icon: <ExclamationCircleOutlined />,
        okText: 'Si',
        cancelText: 'No',
        autoFocusButton: 'cancel',
        onOk() {
          onCancel();
        },
        onCancel() {},
      });
    } else {
      onCancel();
    }
  };

  const handleOnChangeDeposito = (value) => {
    const name = depositosList.find((item) => item.id === value).nombre;

    form.setFieldsValue({
      deposito_nombre: name,
    });
  };

  return (
    <>
      <Modal
        centered
        maskClosable={false}
        visible={visible}
        title={stateData.object.data.id ? 'Editar Depósito' : 'Nuevo Depósito'}
        okText="Guardar"
        cancelText="Salir"
        onCancel={handleOnCancel}
        onOk={() => {
          form
            .validateFields()
            .then((values) => {
              onCreate(values);
            })
            .catch((info) => {
              console.log('Validate Failed:', info);
            });
        }}
      >
        <Form
          form={form}
          layout="vertical"
          name="form_in_modal"
          initialValues={stateData.object.data}
        >
          <Row gutter={[8, 0]}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Form.Item name="id" label="id" style={{ display: 'none' }}>
                <Input />
              </Form.Item>
              <Form.Item name="key" label="key" style={{ display: 'none' }}>
                <Input />
              </Form.Item>
              <Form.Item
                name="deposito_nombre"
                label=""
                style={{ display: 'none' }}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[8, 0]}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Form.Item
                name="deposito_id"
                label="Depósito"
                rules={[
                  {
                    required: true,
                    message: 'Requerido',
                  },
                ]}
              >
                <Select onChange={handleOnChangeDeposito}>
                  {depositosList.map((item) => (
                    <Select.Option key={item.id} value={item.id}>
                      {item.nombre}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item
                name="cantidad"
                label="Cantidad"
                rules={[
                  {
                    required: true,
                    message: 'Requerido',
                  },
                ]}
              >
                <Input type="number" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item name="cantidad_minima" label="Cantidad Min">
                <Input type="number" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <Form.Item
                name="posicion_x"
                label={
                  configuracion.list.data.find(
                    (item) => item.nombre === 'label_posicion_x'
                  ).valor
                }
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <Form.Item
                name="posicion_y"
                label={
                  configuracion.list.data.find(
                    (item) => item.nombre === 'label_posicion_y'
                  ).valor
                }
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <Form.Item
                name="posicion_z"
                label={
                  configuracion.list.data.find(
                    (item) => item.nombre === 'label_posicion_z'
                  ).valor
                }
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => {
  const { productoDeposito, deposito, configuracion } = state;
  const stateData = productoDeposito;
  return {
    stateData,
    deposito,
    configuracion,
  };
};
export default connect(mapStateToProps)(ProductoDepositoEditor);
