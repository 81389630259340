import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Modal, Form, Input, Row, Col, Select, Spin } from 'antd';
import { callStatus } from '../utils/constants';
import { get, save, getAfip } from '../redux/actions/clienteActions';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { compareObject } from '../utils/funtions';
const { confirm, error, success } = Modal;
const { TextArea } = Input;

const ClienteEditor = ({
  dispatch,
  id,
  stateData,
  visible,
  onCancel,
  condicionIva,
}) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (id) {
      dispatch(get(id));
    }
    // eslint-disable-next-line
  }, []);

  const handleShowMessage = (message, type, handler) => {
    switch (type) {
      case 'success':
        success({
          title: message,
          onOk() {
            handler();
          },
        });
        break;
      case 'error':
        error({
          title: message,
          onOk() {
            handler();
          },
        });
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (stateData.object.status === callStatus.OBJECT_SUCCESS) {
      form.setFieldsValue(stateData.object.data);
      setInitial(stateData.object.data);
    } else if (stateData.object.status === callStatus.OBJECT_SAVE_SUCCESS) {
      handleShowMessage('El Cliente se guardó con éxito', 'success', onCancel);
    } else if (stateData.object.status === callStatus.OBJECT_FAILED) {
      handleShowMessage(stateData.object.error, 'error', () => {});
    }

    // eslint-disable-next-line
  }, [stateData.object]);

  const onCreate = (data) => {
    dispatch(save(data));
  };

  const handleOnSearch = (value) => {
    dispatch(getAfip(value));
  };

  const [initial, setInitial] = useState({});

  const handleOnCancel = () => {
    if (compareObject(initial, form.getFieldsValue())) {
      confirm({
        title: 'Posee cambios sin guardar, desea continuar?',
        icon: <ExclamationCircleOutlined />,
        okText: 'Si',
        cancelText: 'No',
        autoFocusButton: 'cancel',
        onOk() {
          onCancel();
        },
        onCancel() {},
      });
    } else {
      onCancel();
    }
  };

  return (
    <>
      <Modal
        centered
        maskClosable={false}
        visible={visible}
        title={stateData.object.data.id ? 'Editar Cliente' : 'Nuevo Cliente'}
        okText="Guardar"
        cancelText="Salir"
        onCancel={handleOnCancel}
        onOk={() => {
          form
            .validateFields()
            .then((values) => {
              onCreate(values);
            })
            .catch((info) => {
              console.log('Validate Failed:', info);
            });
        }}
        width={800}
        bodyStyle={{ minHeight: '500px' }}
      >
        <Spin
          spinning={stateData.object.status === callStatus.OBJECT_IN_PROCESS}
        >
          <Form
            form={form}
            layout="vertical"
            name="form_in_modal"
            initialValues={stateData.object.data}
          >
            <Row gutter={[8, 0]}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Form.Item name="id" label="id" style={{ display: 'none' }}>
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[8, 0]}>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <Form.Item
                  name="nombre"
                  label="Nombre/Razón Social"
                  rules={[
                    {
                      required: true,
                      message: 'Requerido',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>

              {/* <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <Form.Item name="razon_social" label="Razón Social">
                  <Input />
                </Form.Item>
              </Col> */}

              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <Form.Item name="direccion" label="Dirección">
                  <Input />
                </Form.Item>
              </Col>

              <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                <Form.Item name="telefono" label="Teléfono">
                  <Input />
                </Form.Item>
              </Col>

              <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                <Form.Item name="email" label="Email">
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                <Form.Item
                  name="cuit"
                  label="Cuit"
                  rules={[
                    {
                      required: true,
                      message: 'Requerido',
                    },
                  ]}
                >
                  <Input.Search onSearch={handleOnSearch} />
                </Form.Item>
              </Col>

              <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                <Form.Item
                  name="condicion_iva_id"
                  label="Condición IVA"
                  rules={[
                    {
                      required: true,
                      message: 'Requerido',
                    },
                  ]}
                >
                  <Select>
                    {condicionIva.list.data.map((item) => (
                      <Select.Option key={item.id} value={item.id}>
                        {item.nombre}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                <Form.Item name="descuento" label="Descuento">
                  <Input type="number" />
                </Form.Item>
              </Col>

              <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                <Form.Item name="recargo_producto" label="Recargo por producto">
                  <Input type="number" />
                </Form.Item>
              </Col>

              <Col xs={24} sm={12} md={8} lg={8} xl={8}>
                <Form.Item name="saldo_inicial" label="Saldo Inicial">
                  <Input type="number" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={8} lg={8} xl={8}>
                <Form.Item name="estado_cuenta" label="Estado de Cuenta">
                  <Input type="number" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={8} lg={8} xl={8}>
                <Form.Item name="credito" label="Crédito">
                  <Input type="number" />
                </Form.Item>
              </Col>

              <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                <Form.Item name="localidad" label="Localidad">
                  <Input />
                </Form.Item>
              </Col>

              <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                <Form.Item name="provincia" label="Provincia">
                  <Input />
                </Form.Item>
              </Col>

              <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                <Form.Item name="cp" label="Código Postal">
                  <Input />
                </Form.Item>
              </Col>

              <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                <Form.Item name="distancia" label="Distancia">
                  <Input />
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Form.Item name="observaciones" label="Observaciones">
                  <TextArea />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Spin>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => {
  const { cliente, condicionIva } = state;
  const stateData = cliente;
  return {
    stateData,
    condicionIva,
  };
};
export default connect(mapStateToProps)(ClienteEditor);
