import { API_URL } from '../utils/global';

const axiosDef = require('axios').default;

export const defaultErrorHandler = (axiosError) => {
  let error = null;
  if (axiosError.response && axiosError.response.data) {
    error = axiosError.response.data.errors
      ? axiosError.response.data.errors
      : axiosError.response.data.message
      ? axiosError.response.data.message
      : 'Error desconocido';
  } else {
    error = axiosError.message ? axiosError.message : 'Error desconocido';
  }

  if (axiosError.response && axiosError.response.status === 401) {
    sessionStorage.removeItem('auth');
    if (axiosError.config.url !== '/login') {
      window.location.reload();
    }
  }

  return Promise.reject(error);
};

export const defaultSuccesHandler = (response) => {
  return Promise.resolve(response);
};

export function createApi({
  baseURL = `${API_URL}`,
  timeout = 20000,
  headers = { 'Content-Type': 'application/json' },
  withCredentials = false,
  errorHandler = defaultErrorHandler,
  successHandler = defaultSuccesHandler,
  axios = axiosDef.create(),
}) {
  axios.defaults.baseURL = baseURL;
  axios.defaults.timeout = timeout;
  axios.defaults.headers = headers;
  axios.defaults.withCredentials = withCredentials;
  axios.defaults.validateStatus = (status) => {
    return status < 300;
  };
  axios.interceptors.response.use(
    (response) => successHandler(response),
    (error) => errorHandler(error)
  );
  axios.interceptors.request.use(
    (config) => {
      if (sessionStorage.auth) {
        const jwt = JSON.parse(sessionStorage.auth).data.access_token;
        if (jwt) {
          config.headers['Authorization'] = 'Bearer ' + jwt;
        }
      }
      return config;
    },
    (error) => Promise.reject(error)
  );
  return axios;
}

export const authApi = createApi({
  baseURL: `${API_URL}/auth`,
});

export const condicionIvaApi = createApi({
  baseURL: `${API_URL}/condicioniva`,
});

export const monedaApi = createApi({
  baseURL: `${API_URL}/moneda`,
});

export const proveedorApi = createApi({
  baseURL: `${API_URL}/proveedor`,
});

export const clienteApi = createApi({
  baseURL: `${API_URL}/cliente`,
});

export const clienteContactoApi = createApi({
  baseURL: `${API_URL}/cliente-contacto`,
});

export const listaApi = createApi({
  baseURL: `${API_URL}/lista`,
});

export const depositoApi = createApi({
  baseURL: `${API_URL}/deposito`,
});

export const productoApi = createApi({
  baseURL: `${API_URL}/producto`,
});

export const servicioApi = createApi({
  baseURL: `${API_URL}/servicio`,
});

export const productoListaPrecioApi = createApi({
  baseURL: `${API_URL}/producto-lista-precio`,
});

export const productoDepositoApi = createApi({
  baseURL: `${API_URL}/producto-deposito`,
});

export const comprobanteApi = createApi({
  baseURL: `${API_URL}/comprobante`,
});

export const comprobanteTipoApi = createApi({
  baseURL: `${API_URL}/comprobante-tipo`,
});

export const puntoVentaApi = createApi({
  baseURL: `${API_URL}/punto-venta`,
});

export const alicuotaApi = createApi({
  baseURL: `${API_URL}/alicuota`,
});

export const configuracionApi = createApi({
  baseURL: `${API_URL}/configuracion`,
});

export const cobroApi = createApi({
  baseURL: `${API_URL}/cobro`,
});

export const chequeApi = createApi({
  baseURL: `${API_URL}/cheque`,
});

export const tarjetaApi = createApi({
  baseURL: `${API_URL}/tarjeta`,
});

export const retencionApi = createApi({
  baseURL: `${API_URL}/retencion`,
});

export const condicionVentaApi = createApi({
  baseURL: `${API_URL}/condicion-venta`,
});

export const comprobanteObservacionApi = createApi({
  baseURL: `${API_URL}/comprobante-observacion`,
});

export const comprobanteCompraApi = createApi({
  baseURL: `${API_URL}/comprobante-compra`,
});

export const rubroApi = createApi({
  baseURL: `${API_URL}/rubro`,
});

export const pagoApi = createApi({
  baseURL: `${API_URL}/pago`,
});

export const cuotaApi = createApi({
  baseURL: `${API_URL}/cuota`,
});

export const formaPagoApi = createApi({
  baseURL: `${API_URL}/forma-pago`,
});
