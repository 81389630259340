import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';

import {
  proveedorReducer,
  condicionIvaReducer,
  monedaReducer,
  clienteReducer,
  listaReducer,
  depositoReducer,
  productoReducer,
  productoListaPrecioReducer,
  productoDepositoReducer,
  servicioReducer,
  clienteContactoReducer,
  comprobanteReducer,
  comprobanteTipoReducer,
  puntoVentaReducer,
  alicuotaReducer,
  configuracionReducer,
  cobroReducer,
  chequeEstadoReducer,
  chequeTipoReducer,
  tarjetaEmpresaReducer,
  tarjetaTipoReducer,
  retencionTipoReducer,
  cobroTipoReducer,
  condicionVentaReducer,
  chequeReducer,
  comprobanteObservacionReducer,
  comprobanteCompraReducer,
  rubroReducer,
  pagoReducer,
  cuotaReducer,
  formaPagoReducer,
} from './dataReducers';
import AuthReducer from './AuthReducer';

export const rootReducer = combineReducers({
  auth: AuthReducer,
  condicionIva: condicionIvaReducer,
  moneda: monedaReducer,
  proveedor: proveedorReducer,
  cliente: clienteReducer,
  clienteContacto: clienteContactoReducer,
  lista: listaReducer,
  deposito: depositoReducer,
  producto: productoReducer,
  productoListaPrecio: productoListaPrecioReducer,
  productoDeposito: productoDepositoReducer,
  servicio: servicioReducer,
  comprobante: comprobanteReducer,
  comprobanteTipo: comprobanteTipoReducer,
  puntoVenta: puntoVentaReducer,
  alicuota: alicuotaReducer,
  configuracion: configuracionReducer,
  cobro: cobroReducer,
  cheque: chequeReducer,
  chequeEstado: chequeEstadoReducer,
  chequeTipo: chequeTipoReducer,
  tarjetaEmpresa: tarjetaEmpresaReducer,
  tarjetaTipo: tarjetaTipoReducer,
  retencionTipo: retencionTipoReducer,
  cobroTipo: cobroTipoReducer,
  condicionVenta: condicionVentaReducer,
  comprobanteObservacion: comprobanteObservacionReducer,
  comprobanteCompra: comprobanteCompraReducer,
  rubro: rubroReducer,
  pago: pagoReducer,
  cuota: cuotaReducer,
  formaPago: formaPagoReducer,
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk))
);

export default store;
