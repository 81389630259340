import { chequeApi } from '../../utils/api';
import {
  createListDataDeletedPending,
  createObjectDataPending,
  deleteObjectDataAction,
  getListByParentDataAction,
  getListDataAction,
  getListDeletedDataAction,
  getObjectDataAction,
  restoreObjectDataAction,
  saveObjectDataAction,
} from '../../utils/DataAction';

export const get = (id) =>
  getObjectDataAction({
    dataName: 'CHEQUE',
    api: chequeApi,
    relUrl: id,
  });

export const getAll = getListDataAction({
  dataName: 'CHEQUE',
  api: chequeApi,
  relUrl: '',
});

export const getAllByParent = (id) =>
  getListByParentDataAction({
    dataName: 'CHEQUE',
    api: chequeApi,
    relUrl: `indexByParent/${id}`,
  });

export const getByNumero = (value) =>
  getObjectDataAction({
    dataName: 'CHEQUE',
    api: chequeApi,
    relUrl: `search/${value}`,
  });

export const save = (data) =>
  saveObjectDataAction({
    dataName: 'CHEQUE',
    api: chequeApi,
    relUrl: '',
    data: data,
  });

export const deleted = (id) =>
  deleteObjectDataAction({
    dataName: 'CHEQUE',
    api: chequeApi,
    relUrl: id,
  });

export const getAllDeleted = getListDeletedDataAction({
  dataName: 'CHEQUE',
  api: chequeApi,
  relUrl: 'restore',
});

export const restore = (data) =>
  restoreObjectDataAction({
    dataName: 'CHEQUE',
    api: chequeApi,
    relUrl: 'restore',
    data: data,
  });

export const setPending = createObjectDataPending('CHEQUE');
export const setDeletedPending = createListDataDeletedPending('CHEQUE');
