import dayjs from 'dayjs';
import moment from 'moment';
import store from '../redux/store';

const formatDisplay = 'DD/MM/YYYY';

export const compareObject = (initial, form) => {
  let changes = {};

  let initialData = { ...initial };
  delete initialData.created_at;
  delete initialData.updated_at;
  delete initialData.deleted;

  Object.entries(form)
    .filter(
      ([key, value]) =>
        !Array.isArray(value) &&
        typeof value !== 'object' &&
        value !== undefined
    )
    .forEach(([key, value]) => {
      if (!initialData.hasOwnProperty(key) || initialData[key] !== value) {
        changes[key] = value;
      }
    });

  if (Object.values(changes).length > 0) {
    return true;
  } else {
    return false;
  }
};

export const convertDate = (date) => {
  let NewDate = date.split('/').reverse().join('-');

  return NewDate;
};
export const convertDate2 = (date) => {
  let NewDate = dayjs(
    new Date(moment(moment(convertDate(date)))).toISOString()
  ).format(formatDisplay);

  return NewDate;
};

export const viewItems = (tipo, devolucion) => {
  let result = false;
  switch (tipo) {
    case -5:
    case -3:
    case -2:
    case 1:
    case 6:
    case 11:
      result = true;
      break;
    case -6:
    case 3:
    case 8:
    case 13:
      result = devolucion ? true : false;
      break;
    default:
      break;
  }
  return result;
};

export const viewImportes = (tipo, devolucion) => {
  let result = false;
  switch (tipo) {
    case -7:
    case 2:
    case 7:
    case 12:
      result = true;
      break;
    case -6:
    case 3:
    case 8:
    case 13:
      result = !devolucion ? true : false;
      break;
    default:
      break;
  }
  return result;
};

export const validateAsociados = (tipo, devolucion, value) => {
  let result = false;
  switch (tipo) {
    case -6:
      if (devolucion) {
        if (!value || !value.length) {
          result = true;
        }
      }
      break;
    case 2:
    case 7:
    case 3:
    case 8:
    case 12:
    case 13:
      if (!value || !value.length) {
        result = true;
      }
      break;
    default:
      break;
  }
  return result;
};

export const validateItems = (tipo, devolucion, value) => {
  let result = false;
  switch (tipo) {
    case -5:
    case -3:
    case -2:
    case 1:
    case 6:
    case 11:
      if (!value || !value.length) {
        result = true;
      }
      break;
    case -6:
    case 3:
    case 8:
    case 13:
      if (devolucion) {
        if (!value || !value.length) {
          result = true;
        }
      }
      break;
    default:
      break;
  }
  return result;
};

export const viewAlicuotas = (tipo) => {
  switch (tipo) {
    case 1:
    case 2:
    case 3:
      return true;

    default:
      return false;
  }
};

export const getFieldConfig = (data, val) => {
  return data.find((value) => value.nombre === val).valor;
};

export const hasPermiso = (permiso) => {
  const permisos = store.getState().auth.data.data.user.rol.permisos;
  const find = permisos.find((value) => value.permiso_id === permiso);
  return find ? true : false;
};
