import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import { getFieldConfig } from '../../utils/funtions';

// Create styles
const styles = StyleSheet.create({
  contacto: {
    marginBottom: 2,
    fontSize: 10,
  },
});

const EncabezadoEmpresa = ({ data }) => {
  return (
    <View
      fixed
      style={{
        flexDirection: 'row',
        justifyContent: 'space-around',
        alignItems: 'center',
        paddingBottom: 5,
        borderBottom: 1,
        marginTop: '10px',
      }}
    >
      <View
        style={{
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Text style={styles.contacto}>
          {getFieldConfig(data, 'afip_nombre')} CUIT:{' '}
          {getFieldConfig(data, 'afip_cuit')}
        </Text>
        <Text style={styles.contacto}>
          {getFieldConfig(data, 'afip_direccion')}
        </Text>
        <Text style={styles.contacto}>
          Tel {getFieldConfig(data, 'afip_telefono')} -{' '}
          {getFieldConfig(data, 'afip_mail')}
        </Text>
      </View>
      <View
        style={{
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Text style={styles.contacto}>
          Condición IVA: {getFieldConfig(data, 'afip_condicion_iva')}
        </Text>
        <Text style={styles.contacto}>
          Ingresos Brutos: {getFieldConfig(data, 'afip_cuit')}
        </Text>
        <Text style={styles.contacto}>
          Fecha Inicio Actividad:{' '}
          {getFieldConfig(data, 'afip_inicio_actividad')}
        </Text>
      </View>
    </View>
  );
};
export default EncabezadoEmpresa;
