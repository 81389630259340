import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { callStatus } from '../utils/constants';
import { Modal, DatePicker, Form, Input, Row, Col, Spin } from 'antd';
import { get, save } from '../redux/actions/cuotaActions';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import cloneDeep from 'lodash/cloneDeep';
import moment from 'moment';
import { compareObject } from '../utils/funtions';
import dayjs from 'dayjs';
const { confirm, error, success } = Modal;

const CuotaEditor = ({
  dispatch,
  id,
  parentId,
  stateData,
  visible,
  onCancel,
}) => {
  const [form] = Form.useForm();
  const formatDisplay = 'DD/MM/YYYY';
  const format = 'YYYY-MM-DD';
  useEffect(() => {
    if (id) {
      dispatch(get(id));
    }
    // eslint-disable-next-line
  }, []);
  const handleShowMessage = (message, type, handler) => {
    switch (type) {
      case 'success':
        success({
          title: message,
          onOk() {
            handler();
          },
        });
        break;
      case 'error':
        error({
          title: message,
          onOk() {
            handler();
          },
        });
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (stateData.object.status === callStatus.OBJECT_SUCCESS) {
      let data = cloneDeep(stateData.object.data);
      data['fecha'] = moment(moment(data.fecha));

      form.setFieldsValue(data);
      setInitial(data);
    } else if (stateData.object.status === callStatus.OBJECT_SAVE_SUCCESS) {
      handleShowMessage('La Cuota se guardó con éxito', 'success', onCancel);
    } else if (stateData.object.status === callStatus.OBJECT_FAILED) {
      handleShowMessage(stateData.object.error, 'error', () => {});
    }

    // eslint-disable-next-line
  }, [stateData.object]);

  const onCreate = (data) => {
    data.fecha = dayjs(new Date(data.fecha).toISOString()).format(format);
    data.comprobante_id = parentId;

    dispatch(save(data));
  };

  const [initial, setInitial] = useState({});

  const handleOnCancel = () => {
    if (compareObject(initial, form.getFieldsValue())) {
      confirm({
        title: 'Posee cambios sin guardar, desea continuar?',
        icon: <ExclamationCircleOutlined />,
        okText: 'Si',
        cancelText: 'No',
        autoFocusButton: 'cancel',
        onOk() {
          onCancel();
        },
        onCancel() {},
      });
    } else {
      onCancel();
    }
  };

  return (
    <>
      <Modal
        centered
        maskClosable={false}
        visible={visible}
        title={stateData.object.data.id ? 'Editar Cuota' : 'Nuevo Cuota'}
        okText="Guardar"
        cancelText="Salir"
        onCancel={handleOnCancel}
        onOk={() => {
          form
            .validateFields()
            .then((values) => {
              onCreate(values);
            })
            .catch((info) => {
              console.log('Validate Failed:', info);
            });
        }}
      >
        <Spin
          spinning={stateData.object.status === callStatus.OBJECT_IN_PROCESS}
        >
          <Form
            form={form}
            layout="vertical"
            name="form_in_modal"
            initialValues={stateData.object.data}
          >
            <Row gutter={[8, 0]}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Form.Item name="id" label="id" style={{ display: 'none' }}>
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[8, 0]}>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <Form.Item
                  name="fecha"
                  label="Fecha"
                  rules={[
                    {
                      required: true,
                      message: 'Requerido',
                    },
                  ]}
                >
                  <DatePicker
                    format={formatDisplay}
                    style={{ width: '100%' }}
                    initialValue={moment(new Date())}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Form.Item
                  name="importe"
                  label="Importe"
                  rules={[
                    {
                      required: true,
                      message: 'Requerido',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Spin>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => {
  const { cuota } = state;
  const stateData = cuota;
  return {
    stateData,
  };
};
export default connect(mapStateToProps)(CuotaEditor);
