import { Card, Row, Col } from 'antd';
import React from 'react';
import ComprobanteFacturacion from './ComprobanteFacturacion';

const Dashboard = () => {
  return (
    <div>
      <Row gutter={[8, 8]}>
        <Col xs={24} sm={12} md={8} lg={8} xl={8}>
          <Card
            title={'Facturación mensual'}
            bodyStyle={{ padding: 0, height: '350px' }}
          >
            <ComprobanteFacturacion />
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default Dashboard;
