import React, { useState, useEffect } from 'react';
// import ComprobanteEditor from './ComprobanteEditor';
import { connect } from 'react-redux';
import {
  getAll,
  setPending,
  deleted,
  setDeletedPending,
  getAllDeleted,
  restore,
} from '../redux/actions/cobroActions';
import { callStatus } from '../utils/constants';
import { Button, Space, Layout, Modal } from 'antd';
import { withRouter } from 'react-router-dom';
// import Highlighter from 'react-highlight-words';
import {
  // SearchOutlined,
  EditFilled,
  DeleteFilled,
  // DownloadOutlined,
  PlusCircleOutlined,
  SelectOutlined,
  ExclamationCircleOutlined,
  PrinterOutlined,
} from '@ant-design/icons';
import styled from 'styled-components';
import RestorePopup from './RestorePopup';
import { useHistory } from 'react-router-dom';
import dayjs from 'dayjs';
import MetaTable from './base/MetaTable';
import moment from 'moment';
import { convertDate } from '../utils/funtions';
import CobroViewer from './viewer/CobroViewer';
import CustomCellRender from './base/CustomCellRender';
const { confirm } = Modal;

const { Content, Header, Footer } = Layout;

// const HeaderContainer = styled.div`
//   display: flex;
//   align-items: center;
//   width: 100%;
// `;

const SelectedContainer = styled.div`
  flex: 1;
`;

const ActionsContainer = styled.div``;

const FooterContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding-top: 10px;
`;

const CobroList = ({ location, stateData, dispatch }) => {
  const [showRestore, setShowRestore] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [rowEdit, setRowEdit] = useState(null);
  const [showViewer, setShowViewer] = useState(false);

  const history = useHistory();
  const formatDisplay = 'DD/MM/YYYY';

  useEffect(() => {
    getAll(dispatch);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (stateData.list.status === callStatus.LIST_SUCCESS) {
      const data = [
        ...stateData.list.data.map((item) => {
          return {
            ...item,
            fecha: dayjs(
              new Date(moment(moment(convertDate(item.fecha)))).toISOString()
            ).format(formatDisplay),
          };
        }),
      ];

      setDataSource(data);
    }

    // eslint-disable-next-line
  }, [stateData]);

  // const handleEdit = (data) => {
  //   setRowEdit(data);
  //   setShowEdit(true);
  // };

  const handleRestore = () => {
    setShowRestore(true);
    getAllDeleted(dispatch);
  };

  const handleRestoreCancel = () => {
    setShowRestore(false);
    dispatch(setDeletedPending());
  };

  const handleRestoreOk = (data) => {
    dispatch(restore({ data: data }));
    setShowRestore(false);
  };

  const handleDelete = (data) => {
    dispatch(deleted(data.id));
  };

  // const handleHideEditor = () => {
  //   setShowEdit(false);
  //   setRowEdit(null);
  //   dispatch(setPending());
  // };

  const handleConfirm = (item) => {
    confirm({
      title: 'Esta a punto de eliminar un registro, desea continuar?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Si',
      cancelText: 'No',
      autoFocusButton: 'cancel',
      onOk() {
        handleDelete(item);
      },
      onCancel() {},
    });
  };

  const handleEditor = (data) => {
    history.push(`/cobros/cobro/${data.id}`);
  };

  const handleAdd = () => {
    history.push(`/cobros/cobro/0`);
  };

  const handlePrint = (data) => {
    setRowEdit(data);
    setShowViewer(true);
  };

  const handleHideViewer = () => {
    setShowViewer(false);
    setRowEdit(null);
    dispatch(setPending());
  };

  const restoreColumns = [
    {
      ellipsis: true,
      title: 'Número',
      dataIndex: 'numero',
    },
  ];

  const columns = [
    {
      title: '',
      key: 'action',
      render: (text, record) => (
        <Space size="middle">
          <EditFilled onClick={(e) => handleEditor(record)} />
          <DeleteFilled onClick={(e) => handleConfirm(record)} />
          <PrinterOutlined onClick={(e) => handlePrint(record)} />
        </Space>
      ),
      width: 90,
      ellipsis: true,
    },
    {
      width: 100,
      title: 'Tipo',
      dataIndex: 'tipo_nombre',
    },
    {
      width: 100,
      ellipsis: true,
      title: 'Fecha',
      dataIndex: 'fecha',
    },
    {
      width: 200,
      ellipsis: true,
      title: 'Número',
      dataIndex: 'numero',
      sorter: (a, b) => a.numero.localeCompare(b.numero),
      search: true,
      render: (text) => <span>text.padStart(8, '0')</span>,
    },
    {
      width: 300,
      ellipsis: true,
      title: 'Cliente',
      dataIndex: 'cliente_nombre',
      sorter: (a, b) => a.cliente_nombre.localeCompare(b.cliente_nombre),
      search: true,
    },
    {
      width: 200,
      ellipsis: true,
      title: 'Total',
      dataIndex: 'total',
      render: (text) => (
        <CustomCellRender text={text} justify={'space-between'} prefix={'$'} />
      ),
    },
  ];

  return (
    <Layout style={{ height: '100%' }}>
      <Header></Header>
      <Content>
        <MetaTable
          dataSource={dataSource}
          dataColumns={columns}
          bordered={false}
          selection={false}
          loading={stateData.list.status === callStatus.LIST_IN_PROCESS}
          pagination={true}
        />
      </Content>
      <Footer>
        <FooterContainer>
          <SelectedContainer></SelectedContainer>
          <ActionsContainer>
            <Button
              type="primary"
              icon={<PlusCircleOutlined />}
              size="small"
              // onClick={(e) => handleEdit({})}
              onClick={(e) => handleAdd()}
            >
              Agregar
            </Button>
            <Button
              type="primary"
              icon={<SelectOutlined />}
              size="small"
              style={{ marginLeft: '3px' }}
              onClick={(e) => handleRestore()}
            >
              Recuperar
            </Button>
          </ActionsContainer>
        </FooterContainer>
      </Footer>
      {/* {showEdit ? (
        <ComprobanteEditor
          id={rowEdit.id}
          onCancel={handleHideEditor}
          // onCreate={handleSaveEditor}
          visible={true}
        />
      ) : (
        false
      )} */}

      {showViewer && (
        <CobroViewer
          id={rowEdit.id}
          onCancel={handleHideViewer}
          visible={true}
        />
      )}

      {showRestore &&
      stateData.deleted.status === callStatus.LIST_DELETED_SUCCESS ? (
        <RestorePopup
          columns={restoreColumns}
          data={stateData.deleted.data}
          onCancel={handleRestoreCancel}
          onOk={handleRestoreOk}
        />
      ) : (
        false
      )}
    </Layout>
  );
};

const mapStateToProps = (state) => {
  const { cobro } = state;
  const stateData = cobro;
  return {
    stateData,
  };
};
export default connect(mapStateToProps)(withRouter(CobroList));
