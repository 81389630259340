import React from 'react';
import { Text, View, StyleSheet, Image } from '@react-pdf/renderer';

// Create styles
const styles = StyleSheet.create({
  contacto: {
    marginBottom: 2,
    fontSize: 10,
  },
});

const PieAfip = ({ data, qr }) => {
  return (
    <View
      style={{
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: '10px',
      }}
    >
      <View
        style={{
          flexDirection: 'row',
        }}
      >
        <Image src={{ uri: qr }} style={{ width: '100px', height: '100px' }} />
        <View
          style={{
            flexDirection: 'column',
            marginLeft: '10px',
          }}
        >
          <Image
            style={{
              width: '80px',
            }}
            src="logo_afip.png"
          />
          <Text style={styles.contacto}>Comprobante Autorizado</Text>
        </View>
      </View>
      <View
        style={{
          flexDirection: 'column',
        }}
      >
        <Text style={styles.contacto}>CAE Nº: {data.cae}</Text>
        <Text style={styles.contacto}>Vto CAE: {data.cae_vencimiento}</Text>
      </View>
    </View>
  );
};

export default PieAfip;
