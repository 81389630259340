import React, { useEffect, useState } from 'react';
import { Modal, Row, Card, Space } from 'antd';
import {
  ExclamationCircleOutlined,
  PlusCircleOutlined,
  CreditCardOutlined,
  EditFilled,
  DeleteFilled,
} from '@ant-design/icons';
import styled from 'styled-components';
import MetaTable from '../base/MetaTable';
import TarjetaEditor from './TarjetaEditor';
import cloneDeep from 'lodash/cloneDeep';
const { confirm } = Modal;

const TitleCard = styled.div`
  display: flex;
  align-items: center;
`;

const TarjetaList = ({ id, onChange, dataSource }) => {
  const [columns, setColumns] = useState([
    {
      title: '',
      key: 'action',
      dataIndex: 'action',

      render: (text, record) => (
        <Space size="middle">
          <EditFilled onClick={() => handleEdit(record)} />
          <DeleteFilled onClick={() => handleConfirmDelete(record)} />
        </Space>
      ),
      width: 70,
    },
    {
      width: 150,
      title: 'Numero',
      dataIndex: 'numero',
      ellipsis: true,
    },
    {
      width: 150,
      title: 'Tipo',
      dataIndex: 'tipo_nombre',
    },
    {
      width: 150,
      title: 'Empresa',
      dataIndex: 'empresa_nombre',
    },
    {
      width: 150,
      title: 'Total',
      dataIndex: 'total',
    },
    {
      width: 120,
      title: 'Lote',
      dataIndex: 'lote',
      ellipsis: true,
    },
    {
      width: 120,
      title: 'Transaccion',
      dataIndex: 'transaccion',
      ellipsis: true,
    },

    {
      width: 200,
      title: 'Banco',
      dataIndex: 'banco',
      ellipsis: true,
    },
  ]);
  const [rows, setRows] = useState([]);
  const [rowEdit, setRowEdit] = useState(null);
  const [showEdit, setShowEdit] = useState(null);

  const handleSave = (data) => {
    if (rowEdit) {
      editRow(data);
    } else {
      addRow(data);
    }

    setShowEdit(false);
    setRowEdit(null);
  };

  const addRow = (data) => {
    data.key = rows.length ? rows[rows.length - 1].key + 1 : 0;
    const newArray = [...rows, data];
    setRows(newArray);
  };

  const editRow = (data) => {
    const newArray = [
      ...rows.map((item) => {
        if (item.key === data.key) {
          return {
            ...data,
          };
        } else {
          return item;
        }
      }),
    ];
    setRows(newArray);
  };

  const handleEdit = (value) => {
    let data = cloneDeep(value);

    setRowEdit(data);
    setShowEdit(true);
  };

  const handleDelete = (data) => {
    setRows((prev) => {
      return [...prev.filter((item) => item.key !== data.key)];
    });
  };

  const handleHideEditor = () => {
    setShowEdit(false);
    setRowEdit(null);
    // dispatch(setPending());
  };

  const handleConfirmDelete = (item) => {
    confirm({
      title: 'Esta a punto de eliminar un registro, desea continuar?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Si',
      cancelText: 'No',
      autoFocusButton: 'cancel',
      onOk() {
        handleDelete(item);
      },
      onCancel() {},
    });
  };

  useEffect(() => {
    onChange(rows);
    // eslint-disable-next-line
  }, [rows]);

  useEffect(() => {
    if (dataSource && dataSource.length > 0) {
      setRows(dataSource);
    }
  }, [dataSource]);

  useEffect(() => {
    if (id) {
      setColumns((prev) => {
        return prev.filter((item) => item.dataIndex !== 'action');
      });
    }
  }, [id]);

  return (
    <>
      <Card
        extra={
          !id && (
            <PlusCircleOutlined
              style={{ fontSize: '18px' }}
              onClick={() => handleEdit(null)}
            />
          )
        }
        title={
          <TitleCard>
            <CreditCardOutlined
              style={{ fontSize: '22px', color: '#4f43ba' }}
            />
            <span style={{ marginLeft: '3px' }}>Tarjetas</span>
          </TitleCard>
        }
        style={{ marginBottom: '10px' }}
        size="small"
        bodyStyle={rows.length === 0 ? { padding: 0 } : {}}
      >
        <Row>
          {rows.length > 0 && (
            <MetaTable
              dataSource={rows}
              dataColumns={columns}
              bordered={false}
              selection={false}
              cssStyle={true}
            />
          )}
        </Row>
      </Card>

      {showEdit && (
        <TarjetaEditor
          dataSource={rowEdit}
          onCancel={handleHideEditor}
          onCreate={handleSave}
          visible={true}
        />
      )}
    </>
  );
};

export default TarjetaList;
