import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import { columnsPrint } from '../../utils/constants';

// Create styles
const styles = StyleSheet.create({
  text: {
    marginBottom: 3,
    fontSize: 14,
  },
  tableHeader: {
    fontSize: 10,
  },
  tableData: {
    paddingLeft: 2,
    paddingRight: 2,
    fontSize: 10,
  },
});

const ComprobanteViewerItems = ({ data }) => {
  return (
    <>
      <View
        fixed
        style={{
          flexDirection: 'row',
          border: 1,
          padding: 3,
        }}
      >
        {columnsPrint[data.tipo_id].map((col, idx) => {
          return (
            <Text
              style={[
                styles.tableHeader,
                { paddingLeft: '3px' },
                col.index === 'descripcion'
                  ? { flex: 1 }
                  : { width: col.width },
                col.index === 'subtotal' ||
                (col.index === 'cantidad' && data.tipo_id === -3)
                  ? {}
                  : { borderRight: 1 },
              ]}
              key={idx}
            >
              {col.title}
            </Text>
          );
        })}
      </View>
      {data.items.map((item, idx) => (
        <View
          style={
            !item.parent_id
              ? { flexDirection: 'row', padding: 3 }
              : { flexDirection: 'row', padding: 3, paddingLeft: '20px' }
          }
          key={idx}
        >
          {columnsPrint[data.tipo_id].map((col, idx2) => {
            return (
              <Text
                style={[
                  styles.tableData,
                  { border: 'solid red 1px' },
                  col.index === 'descripcion'
                    ? { textAlign: 'left', flex: 1 }
                    : { textAlign: 'right', width: col.width },
                ]}
                key={idx2}
              >
                {(col.index === 'alicuota' || col.index === 'descuento') &&
                !item.parent_id
                  ? `${item[col.index]}%`
                  : item[col.index]}
              </Text>
            );
          })}
        </View>
      ))}
    </>
  );
};
export default ComprobanteViewerItems;
