import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { callStatus } from '../utils/constants';
import {
  Modal,
  Form,
  Input,
  Row,
  Col,
  Select,
  Spin,
  Tabs,
  Card,
  Descriptions,
} from 'antd';
import { get, save } from '../redux/actions/productoActions';
import { getAll as proveedores } from '../redux/actions/proveedorActions';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { compareObject } from '../utils/funtions';
import ProductoDepositoList from './ProductoDeposito/ProductoDepositoList';
import cloneDeep from 'lodash/cloneDeep';

const { TabPane } = Tabs;
const { confirm, error, success } = Modal;
const { Option } = Select;

const ProductoEditor = ({
  dispatch,
  id,
  stateData,
  visible,
  onCancel,
  moneda,
  proveedor,
  alicuota,
  configuracion,
  tabActive = '1',
}) => {
  const [form] = Form.useForm();
  const [resultSearch, setResultSearch] = useState([]);
  const [proveedorList, setProveedorList] = useState(null);
  const [listasprecios, setListasprecios] = useState([]);
  const [cotizacionDolar, setCotizacionDolar] = useState(1);

  useEffect(() => {
    if (id) {
      dispatch(get(id));
    }

    proveedores(dispatch);

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (configuracion.list.status === callStatus.LIST_SUCCESS) {
      setCotizacionDolar(
        configuracion.list.data.find(
          (value) => value.nombre === 'cotizacion_dolar'
        ).valor
      );
    }
  }, [configuracion.list]);

  const handleShowMessage = (message, type, handler) => {
    switch (type) {
      case 'success':
        success({
          title: message,
          onOk() {
            handler();
          },
        });
        break;
      case 'error':
        error({
          title: message,
          onOk() {
            handler();
          },
        });
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    let data = cloneDeep(stateData.object.data);

    if (stateData.object.status === callStatus.OBJECT_SUCCESS) {
      if (stateData.object.data.proveedor) {
        setResultSearch([stateData.object.data.proveedor]);
      }

      data['depositos'] = data.depositos
        .map((item) => {
          return {
            ...item,
            key: item.id,
          };
        })
        .filter((item) => item.deposito.deleted === 0);

      data['listasprecios'] = data.listasprecios.filter(
        (item) => item.lista.deleted === 0
      );

      setListasprecios(data['listasprecios']);

      form.setFieldsValue(data);
      setInitial(data);
    } else if (stateData.object.status === callStatus.OBJECT_SAVE_SUCCESS) {
      handleShowMessage('El Producto se guardó con éxito', 'success', onCancel);
    } else if (stateData.object.status === callStatus.OBJECT_FAILED) {
      handleShowMessage(stateData.object.error, 'error', () => {});
    }

    // eslint-disable-next-line
  }, [stateData.object]);

  useEffect(() => {
    setProveedorList(proveedor.list.data);
    // eslint-disable-next-line
  }, [proveedor]);

  const onCreate = (data) => {
    dispatch(save(data));
  };

  const onSearch = (value) => {
    const result = proveedorList.filter((item) =>
      item.nombre.toLowerCase().includes(value.toLowerCase())
    );
    setResultSearch(result);
  };

  const onChange = (value) => {};

  const [initial, setInitial] = useState({});

  const handleOnCancel = () => {
    if (compareObject(initial, form.getFieldsValue())) {
      confirm({
        title: 'Posee cambios sin guardar, desea continuar?',
        icon: <ExclamationCircleOutlined />,
        okText: 'Si',
        cancelText: 'No',
        autoFocusButton: 'cancel',
        onOk() {
          onCancel();
        },
        onCancel() {},
      });
    } else {
      onCancel();
    }
  };
  // const [depositos, setDepositos] = useState([]);

  // const [showAddDeposito, setShowAddDeposito] = useState(false);

  // const handleDepositoCancel = () => {
  //   setShowAddDeposito(false);
  // };

  // const handleDepositoOk = (data) => {
  //   let depositos = form.getFieldValue('depositos')
  //     ? form.getFieldValue('depositos')
  //     : [];

  //   depositos = [
  //     ...depositos,
  //     ...data.map((item) => {
  //       if (!depositos.some((value) => value.id === item.id)) {
  //         return {
  //           ...item,
  //         };
  //       } else {
  //         return null;
  //       }
  //     }),
  //   ];

  //   setShowAddDeposito(false);

  //   setDepositos(depositos.filter((item) => item !== null));
  // };

  // useEffect(() => {
  //   form.setFieldsValue({
  //     depositos: depositos,
  //   });
  //   // eslint-disable-next-line
  // }, [depositos]);

  // const handleDeleteDeposito = (data) => {
  //   setDepositos((prev) => {
  //     return [...prev.filter((item) => item.key !== data.key)];
  //   });
  // };

  // const handleConfirmDeletDeposito = (item) => {
  //   confirm({
  //     title: 'Esta a punto de eliminar un registro, desea continuar?',
  //     icon: <ExclamationCircleOutlined />,
  //     okText: 'Si',
  //     cancelText: 'No',
  //     autoFocusButton: 'cancel',
  //     onOk() {
  //       handleDeleteDeposito(item);
  //     },
  //     onCancel() {},
  //   });
  // };

  ////////////////////

  const handleSetDepositos = (data) => {
    form.setFieldsValue({
      depositos: data,
    });
  };

  return (
    <>
      <Modal
        centered
        maskClosable={false}
        visible={visible}
        title={
          stateData.object.data.id
            ? `${stateData.object.data.nombre}`
            : 'Nuevo Producto'
        }
        okText="Guardar"
        cancelText="Salir"
        onCancel={handleOnCancel}
        onOk={() => {
          form
            .validateFields()
            .then((values) => {
              onCreate(values);
            })
            .catch((info) => {
              console.log('Validate Failed:', info);
            });
        }}
        bodyStyle={{ height: '470px' }}
        width={700}
      >
        <Spin
          spinning={stateData.object.status === callStatus.OBJECT_IN_PROCESS}
        >
          <Form
            form={form}
            layout="vertical"
            name="form_in_modal"
            initialValues={stateData.object.data}
          >
            <Tabs defaultActiveKey={tabActive} size="small">
              <TabPane tab="General" key="1">
                <Row gutter={[8, 0]}>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <Form.Item name="id" label="id" style={{ display: 'none' }}>
                      <Input />
                    </Form.Item>
                    <Form.Item
                      name="depositos"
                      label="depositos"
                      style={{ display: 'none' }}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={[8, 0]}>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <Form.Item
                      name="nombre"
                      label="Nombre"
                      rules={[
                        {
                          required: true,
                          message: 'Requerido',
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>

                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <Form.Item name="proveedor_id" label="Proveedor">
                      <Select
                        showSearch
                        defaultActiveFirstOption={false}
                        showArrow={false}
                        filterOption={false}
                        onSearch={onSearch}
                        onChange={onChange}
                        notFoundContent={null}
                        allowClear
                      >
                        {resultSearch.map((item, index) => (
                          <Option key={index} value={item.id}>
                            {item.nombre}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                    <Form.Item
                      name="codigo"
                      label="Código"
                      rules={[
                        {
                          required: true,
                          message: 'Requerido',
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>

                  <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                    <Form.Item
                      name="precio_compra"
                      label="Precio Compra"
                      rules={[
                        {
                          required: true,
                          message: 'Requerido',
                        },
                      ]}
                    >
                      <Input type="number" />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                    <Form.Item name="alicuota_id" label="Alicuota Iva">
                      <Select>
                        {alicuota.list.data.map((item) => (
                          <Select.Option key={item.id} value={item.id}>
                            {item.nombre}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                    <Form.Item
                      name="moneda_id"
                      label="Moneda"
                      rules={[
                        {
                          required: true,
                          message: 'Requerido',
                        },
                      ]}
                    >
                      <Select>
                        {moneda.list.data.map((item) => (
                          <Select.Option key={item.id} value={item.id}>
                            {item.nombre}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
              </TabPane>
              <TabPane tab="Depósitos" key="2">
                <ProductoDepositoList
                  id={stateData.object.data.id}
                  dataSource={
                    form.getFieldValue('depositos') &&
                    form.getFieldValue('depositos').length
                      ? form.getFieldValue('depositos')
                      : null
                  }
                  onChange={handleSetDepositos}
                  config={configuracion.list.data}
                />
              </TabPane>
              <TabPane
                tab="Listas de Precio"
                key="3"
                style={{ height: '420px', overflow: 'auto' }}
              >
                {listasprecios &&
                  listasprecios.map((item) => (
                    <Card
                      bodyStyle={{ padding: '10px' }}
                      style={{ marginBottom: '10px' }}
                    >
                      <Descriptions title={item.lista.nombre} size="small">
                        <Descriptions.Item label="Precio Compra">
                          {stateData.object.data.moneda_id === 1 ? '$' : 'U$S'}{' '}
                          {item.precio_compra}
                        </Descriptions.Item>
                        <Descriptions.Item label="Ganancia">
                          {item.ganancia} %
                        </Descriptions.Item>
                        <Descriptions.Item label="Precio">
                          {stateData.object.data.moneda_id === 1 ? '$' : 'U$S'}{' '}
                          {item.precio}
                        </Descriptions.Item>
                        <Descriptions.Item label="Alicuota">
                          {item.alicuota.nombre}%
                        </Descriptions.Item>
                        <Descriptions.Item
                          label="Precio Final"
                          contentStyle={{ color: 'red' }}
                        >
                          {stateData.object.data.moneda_id === 1 ? '$' : 'U$S'}{' '}
                          {(
                            parseFloat(item.precio) +
                            (parseFloat(item.precio) *
                              parseFloat(item.alicuota.nombre)) /
                              100
                          ).toFixed(2)}
                        </Descriptions.Item>
                        {stateData.object.data.moneda_id === 2 && (
                          <Descriptions.Item
                            label="Precio Final Pesos"
                            contentStyle={{ color: 'red' }}
                          >
                            ${' '}
                            {(
                              parseFloat(item.precio) * cotizacionDolar +
                              (parseFloat(item.precio) *
                                cotizacionDolar *
                                parseFloat(item.alicuota.nombre)) /
                                100
                            ).toFixed(2)}
                          </Descriptions.Item>
                        )}
                      </Descriptions>
                    </Card>
                  ))}
              </TabPane>
            </Tabs>
          </Form>
        </Spin>
        {/* 
        {showAddDeposito ? (
          <ProductoDepositoPopup
            producto={stateData.id}
            onCancel={handleDepositoCancel}
            onOk={handleDepositoOk}
            itemSelected={form.getFieldValue('depositos')}
          />
        ) : (
          false
        )} */}
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => {
  const { producto, moneda, proveedor, alicuota, configuracion } = state;
  const stateData = producto;
  return {
    stateData,
    moneda,
    proveedor,
    alicuota,
    configuracion,
  };
};
export default connect(mapStateToProps)(ProductoEditor);
