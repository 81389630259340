import { PlusCircleOutlined } from '@ant-design/icons';
import { Button, Layout } from 'antd';
import dayjs from 'dayjs';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { comprobanteCompraApi } from '../utils/api';
import { convertDate } from '../utils/funtions';
import CustomCellRender from './base/CustomCellRender';
import MetaTable from './base/MetaTable';
import CtaCteViewer from './viewer/CtaCteViewer';

const { Content, Header, Footer } = Layout;

const SelectedContainer = styled.div`
  flex: 1;
`;

const ActionsContainer = styled.div``;

const FooterContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding-top: 10px;
`;

const ProveedorCtaCteList = ({
  dispatch,
  match: {
    params: { id },
  },
}) => {
  const [data, setData] = useState(null);
  const [columns] = useState([
    {
      width: 100,
      title: 'Fecha',
      dataIndex: 'fecha',
    },
    {
      width: 150,
      title: 'Número',
      dataIndex: 'numero',
    },
    {
      width: 150,
      title: 'Tipo',
      dataIndex: 'tipo_nombre',
    },
    {
      width: 150,
      title: 'Debe',
      dataIndex: 'debe',
      render: (text) => (
        <CustomCellRender text={text} justify={'space-between'} prefix={'$'} />
      ),
    },
    {
      width: 150,
      title: 'Haber',
      dataIndex: 'haber',
      render: (text) => (
        <CustomCellRender text={text} justify={'space-between'} prefix={'$'} />
      ),
    },
    {
      width: 150,
      title: 'Saldo',
      dataIndex: 'saldo',
      render: (text) => (
        <CustomCellRender text={text} justify={'space-between'} prefix={'$'} />
      ),
    },
  ]);
  const [rows, setRows] = useState([]);

  const formatDisplay = 'DD/MM/YYYY';

  useEffect(() => {
    if (data) {
      setRows(data.comprobantes);
    }

    // eslint-disable-next-line
  }, [data]);

  useEffect(() => {
    if (id) {
      comprobanteCompraApi
        .post(`/ctaCte/${id}`)
        .then((response) => {
          const data = {
            ...response.data,
            comprobantes: [
              ...response.data.comprobantes.map((item) => {
                return {
                  ...item,
                  fecha: dayjs(
                    new Date(
                      moment(moment(convertDate(item.fecha)))
                    ).toISOString()
                  ).format(formatDisplay),
                };
              }),
            ],
          };
          setData(data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
    // eslint-disable-next-line
  }, []);

  const [showViewer, setShowViewer] = useState(false);

  return (
    <Layout style={{ height: '100%' }}>
      <Header style={{ lineHeight: 'normal', marginBottom: '10px' }}>
        {data && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <h2>
              {data.nombre} - {data.cuit}
            </h2>
            {/* <h4>
              {data.direccion} - Saldo inicial: ${data.saldo_inicial} - Estado
              de Cuenta: ${data.estado_cuenta}
            </h4> */}
          </div>
        )}
      </Header>
      <Content>
        {rows.length > 0 && (
          <MetaTable
            dataSource={rows}
            dataColumns={columns}
            bordered={true}
            selection={false}
          />
        )}
      </Content>
      <Footer>
        <FooterContainer>
          <SelectedContainer></SelectedContainer>
          <ActionsContainer>
            <Button
              type="primary"
              icon={<PlusCircleOutlined />}
              size="small"
              onClick={() => {
                setShowViewer(true);
              }}
            >
              Exportar
            </Button>
          </ActionsContainer>
        </FooterContainer>
      </Footer>

      {showViewer && (
        <CtaCteViewer
          data={data}
          onCancel={() => setShowViewer(false)}
          visible={true}
        />
      )}
    </Layout>
  );
};

export default ProveedorCtaCteList;
