import React, { useState, useEffect, useRef } from 'react';
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';
import ReactDragListView from 'react-drag-listview';
import styled from 'styled-components';
import { Button, Space, Table, Input } from 'antd';
import { Resizable } from 'react-resizable';
import { DragOutlined } from '@ant-design/icons';
// import useCurrentRow from './useCurrentRow';
import { css } from '@emotion/css';

const Container = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
`;

const ResizableTitle = ({ onResize, onResizeStop, width, ...restProps }) => {
  if (!width) {
    return <th {...restProps} />;
  }

  return (
    <Resizable
      width={width}
      height={0}
      handle={
        <span
          className="react-resizable-handle"
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
          }}
        />
      }
      onResize={onResize}
      draggableOpts={{ enableUserSelectHack: false }}
      onResizeStop={onResizeStop}
    >
      <th {...restProps} />
    </Resizable>
  );
};

function TableCurrentRow({
  components,
  dataSource,
  onRow,
  rowSelection,
  cssStyle,
  expandable,
  ...props
}) {
  // const { currentRowComponents, handleOnRow } = useCurrentRow({
  //   components,
  //   dataSource,
  //   onRow,
  //   rowSelection,
  // });

  return (
    <Table
      {...props}
      expandable={expandable}
      components={components}
      dataSource={dataSource}
      // onRow={handleOnRow}
      rowSelection={rowSelection}
      className={
        cssStyle &&
        css`
          .ant-table {
            .ant-table-container {
              .ant-table-body {
                max-height: none !important;
              }
            }
          }
        `
      }
    />
  );
}

const MetaTable = ({
  dataColumns,
  dataSource,
  bordered = true,
  pagination = false,
  selection = true,
  handleRowOnDrag = () => {},
  handleHeaderOnDrag = () => {},
  handleHeaderOnClick = () => {},
  handleRowOnClick = () => {},
  handleRowOnDoubleClick = () => {},
  handleRowOnContextMenu = () => {},
  handleRowOnMouseEnter = () => {},
  handleRowOnMouseLeave = () => {},
  handleResizeStop = () => {},
  handleSelectedRowKeys = () => {},
  dragColumns = false,
  dragRows = false,
  cssStyle = false,
  loading = false,
  expandable,
  externalSearch = '',
}) => {
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const [expandedRowKey, setExpandedRowKey] = useState(null);

  useEffect(() => {
    let newCols = dragRows
      ? [
          {
            title: '',
            key: 'operate',
            width: 33,
            render: (text, record, index) => (
              // eslint-disable-next-line
              <a className="drag-handle" href="#">
                <DragOutlined style={{ color: 'black' }} />
              </a>
            ),
          },
          ...dataColumns,
          {
            title: '',
            key: 'operate',
          },
        ]
      : [
          ...dataColumns,
          {
            title: '',
            key: 'operate',
          },
        ];

    const columnsResult = newCols.map((col) => {
      let newCol = { ...col };
      if (!col.hidden) {
        if (col.search) {
          newCol = {
            ...newCol,
            ...getColumnSearchProps(col.dataIndex),
          };
        }

        return newCol;
      } else {
        return null;
      }
    });
    setColumns(columnsResult.filter((item) => item !== null));
    // eslint-disable-next-line
  }, [dataColumns]);

  useEffect(() => {
    let array = [
      ...dataSource.map((value, index) => {
        return {
          ...value,
          key: value.id ? value.id : value.key ? value.key : index,
          index: index,
        };
      }),
    ];

    setRows(array);
    // if (array.length > 0) {
    //   setExpandedRowKey(array[0].key);
    // }
  }, [dataSource]);

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const onSelectChange = (selectedRowKeys) => {
    setSelectedRowKeys(selectedRowKeys);
    handleSelectedRowKeys(selectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  /////////SEARCH///////////////////////////////////////////////////////////////
  const [searchText, setSearchText] = useState(externalSearch);
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);

  const refContentTable = useRef(null);
  const [heightTable, setHeightTable] = useState(0);

  useEffect(() => {
    setExpandedRowKey(null);
  }, [externalSearch]);

  useEffect(() => {
    const updateWidth = () => {
      if (refContentTable && refContentTable.current) {
        const newHeight = refContentTable.current.clientHeight - 50;
        if (newHeight !== heightTable) {
          setHeightTable(newHeight);
        }
      }
    };
    updateWidth();
    window.addEventListener('resize', updateWidth);
  });

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Buscar ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Buscar
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Resetear
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) => {
      return record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : '';
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          // searchInput.select();
        }, 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  //////////END SEARCH////////////////////////////////////////////////////////////////////////

  const dragProps = dragColumns
    ? {
        onDragEnd(fromIndex, toIndex) {
          if (fromIndex > 1) {
            const cols = [...columns];
            const item = cols.splice(fromIndex - 1, 1)[0];
            cols.splice(toIndex - 1, 0, item);
            setColumns(cols);
            handleHeaderOnDrag(fromIndex, toIndex);
          }
        },
        nodeSelector: 'th',
      }
    : null;

  const dragPropsRows = dragRows
    ? {
        onDragEnd(fromIndex, toIndex) {
          if (fromIndex > 0) {
            const data = [...rows];
            const item = data.splice(fromIndex - 1, 1)[0];
            data.splice(toIndex - 1, 0, item);
            setRows(data);
            handleRowOnDrag(fromIndex, toIndex);
          }
        },
        handleSelector: 'a',
      }
    : null;

  const components = {
    header: {
      cell: ResizableTitle,
    },
  };

  const handleResize = (index) => (e, { size }) => {
    e.stopPropagation();
    e.preventDefault();
    if (index > 0) {
      const nextColumns = [...columns];
      nextColumns[index] = {
        ...nextColumns[index],
        width: size.width,
      };

      setColumns(nextColumns);
    }
  };

  const onResizeStop = (index) => (e, { size }) => {
    e.stopPropagation();
    e.preventDefault();
    if (index > 0) {
      handleResizeStop(index, size);
    }
  };

  const colsData = columns.map((col, index) => ({
    ...col,
    onHeaderCell: (column) => ({
      width: column.width,
      onResize: handleResize(index),
      onResizeStop: onResizeStop(index),
    }),
  }));

  // const BaseTable = ({ components }) => {
  //   return (
  //     <TableCurrentRow
  //       components={components}
  //       columns={colsData}
  //       dataSource={rows}
  //       size="small"
  //       rowSelection={selection && rowSelection}
  //       pagination={
  //         pagination
  //           ? {
  //               position: ['topRight'],
  //               size: 'small',
  //               showSizeChanger: true,
  //               showQuickJumper: true,
  //             }
  //           : false
  //       }
  //       bordered={bordered}
  //       sticky
  //       scroll={{ x: '100%', y: heightTable }}
  //       onRow={(record, rowIndex) => {
  //         return {
  //           onClick: (e) => handleRowOnClick(e, record, rowIndex), // click row
  //           onDoubleClick: (e) => handleRowOnDoubleClick(e, record, rowIndex), // double click row
  //           onContextMenu: (e) => handleRowOnContextMenu(e, record, rowIndex), // right button click row
  //           onMouseEnter: (e) => handleRowOnMouseEnter(e, record, rowIndex), // mouse enter row
  //           onMouseLeave: (e) => handleRowOnMouseLeave(e, record, rowIndex), // mouse leave row
  //         };
  //       }}
  //       onHeaderRow={(columns, index) => {
  //         return {
  //           onClick: (e) => handleHeaderOnClick(e, columns, index), // click header row
  //         };
  //       }}
  //     />
  //   );
  // };

  return (
    <Container ref={refContentTable}>
      <ReactDragListView.DragColumn {...dragProps}>
        <ReactDragListView {...dragPropsRows}>
          <TableCurrentRow
            components={components}
            columns={colsData}
            dataSource={rows}
            size="small"
            rowSelection={selection && rowSelection}
            pagination={
              pagination
                ? {
                    position: ['topRight'],
                    size: 'small',
                    defaultCurrent: 1,
                    pageSize: 100,
                    showSizeChanger: true,
                    showQuickJumper: true,
                    pageSizeOptions: [100, 200, 500, 1000],
                  }
                : false
            }
            bordered={bordered}
            sticky
            scroll={{ x: '100%', y: heightTable }}
            onRow={(record, rowIndex) => {
              return {
                onClick: (e) => handleRowOnClick(e, record, rowIndex), // click row
                onDoubleClick: (e) =>
                  handleRowOnDoubleClick(e, record, rowIndex), // double click row
                onContextMenu: (e) =>
                  handleRowOnContextMenu(e, record, rowIndex), // right button click row
                onMouseEnter: (e) => handleRowOnMouseEnter(e, record, rowIndex), // mouse enter row
                onMouseLeave: (e) => handleRowOnMouseLeave(e, record, rowIndex), // mouse leave row
              };
            }}
            onHeaderRow={(columns, index) => {
              return {
                onClick: (e) => handleHeaderOnClick(e, columns, index), // click header row
              };
            }}
            cssStyle={cssStyle}
            loading={loading}
            expandable={
              expandable
                ? {
                    expandedRowRender: (record, index, indent, expaned) =>
                      expaned ? expandable(record, externalSearch) : null,
                    // rowExpandable: (record) => record.name !== 'Not Expandable',
                    expandedRowKeys: [expandedRowKey],
                    onExpand: (expanded, record) => {
                      if (expanded) {
                        setExpandedRowKey(record.key);
                      } else {
                        setExpandedRowKey(null);
                      }
                    },
                  }
                : null
            }
          />
        </ReactDragListView>
      </ReactDragListView.DragColumn>
    </Container>
  );
};

export default MetaTable;
