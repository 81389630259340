import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { get } from '../../redux/actions/cobroActions';
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFViewer,
} from '@react-pdf/renderer';
import { Modal } from 'antd';
import { callStatus } from '../../utils/constants';
import cloneDeep from 'lodash/cloneDeep';
import CobroViewerComprobantes from './CobroViewerComprobantes';
import CobroViewerMedios from './CobroViewerMedios';
import CobroViewerAnticipo from './CobroViewerAnticipo';
import EncabezadoEmpresa from './EncabezadoEmpresa';
import EncabezadoCliente from './EncabezadoCliente';
import Encabezado from './Encabezado';
import { useState } from 'react';

// import ComprobanteViewerWithoutItems from './ComprobanteViewerWithoutItems';
// import ComprobanteViewerItems from './ComprobanteViewerItems';
// import ComprobanteViewerAlicuotas from './ComprobanteViewerAlicuotas';

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: 'white',
  },
  section: {
    margin: 10,
    flexGrow: 1,
  },
  text: {
    marginTop: 5,
    fontSize: 14,
  },
  footer: {
    fontSize: 12,
  },
  contacto: {
    marginBottom: 2,
    fontSize: 10,
  },
  tableHeader: {
    fontSize: 10,
  },
  tableData: {
    paddingLeft: 2,
    paddingRight: 2,
    fontSize: 10,
  },
});

const MyDocument = ({ stateData, config }) => {
  let data = cloneDeep(stateData);

  let totalComprobantes = 0;
  if (data.comprobantes) {
    data.comprobantes.forEach((item) => {
      totalComprobantes = totalComprobantes + parseFloat(item.total);
    });
  }
  if (data.cuotas) {
    data.cuotas.forEach((item) => {
      totalComprobantes = totalComprobantes + parseFloat(item.total);
    });
  }

  let totalEjecuciones = 0;

  if (data.ejecuciones) {
    data.ejecuciones.forEach((item) => {
      totalEjecuciones = totalEjecuciones + parseFloat(item.total);
    });
  }

  let totalCobrar =
    data.tipo_id === 1
      ? (totalComprobantes - totalEjecuciones).toFixed(2)
      : data.total;

  return (
    <Document size="A4">
      <Page style={{ padding: 15 }} wrap>
        <Encabezado data={data} type="cobro" />

        <EncabezadoEmpresa data={config} />

        <EncabezadoCliente data={data} qr={null} />

        {data.comprobantes.length > 0 && (
          <CobroViewerComprobantes
            data={[...data.comprobantes, ...data.cuotas]}
            title={'Comprobantes Liquidados'}
          />
        )}

        {data.ejecuciones.length > 0 && (
          <CobroViewerComprobantes
            data={data.ejecuciones}
            title={'Créditos Ejecutados'}
          />
        )}

        <View
          style={{
            flexDirection: 'row',
            border: 1,
            padding: 3,
            justifyContent: 'flex-end',
            alignItems: 'center',
            marginTop: '20px',
          }}
        >
          <View
            style={{
              flexDirection: 'row',
            }}
          >
            <Text style={styles.footer}>
              {data.tipo_id === 1 ? 'Total a cobrar: ' : 'Total: '}
            </Text>
            <Text style={styles.footer}>${totalCobrar}</Text>
          </View>
        </View>

        <CobroViewerMedios data={data} />

        {data.anticipo && <CobroViewerAnticipo data={data.anticipo} />}

        <View style={{ flex: 1 }}></View>
      </Page>
    </Document>
  );
};

const CobroViewer = ({
  dispatch,
  stateData,
  configData,
  id,
  visible,
  onCancel,
}) => {
  const [loadPDF, setLoadPDF] = useState(false);

  useEffect(() => {
    if (id) {
      dispatch(get(id));
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (
      stateData.object.status === callStatus.OBJECT_SUCCESS &&
      configData.list.status === callStatus.LIST_SUCCESS
    ) {
      setLoadPDF(true);
    }
  }, [stateData, configData]);

  return (
    <Modal
      centered
      maskClosable={false}
      visible={visible}
      title="Impresión Recibo"
      onCancel={onCancel}
      width={700}
      bodyStyle={{ padding: 0, background: '#3a3d3f ' }}
      footer={null}
    >
      {loadPDF && (
        <PDFViewer
          style={{
            width: '100%',
            height: '400px',
            border: 'none',
            margin: 0,
            padding: 0,
            marginTop: '-2px',
          }}
        >
          <MyDocument
            stateData={stateData.object.data}
            config={configData.list.data}
          />
        </PDFViewer>
      )}
    </Modal>
  );
};

const mapStateToProps = (state) => {
  const { cobro, configuracion } = state;
  const stateData = cobro;
  const configData = configuracion;

  return {
    stateData,
    configData,
  };
};
export default connect(mapStateToProps)(CobroViewer);
