import React from 'react';
import { EditFilled, DeleteFilled } from '@ant-design/icons';
import styled from 'styled-components';

const IconStyleBox = {
  fontSize: '20px',
  marginLeft: '10px',
};

const TitleStyleBox = {
  fontWeight: 'bold',
  color: '#00a5a5',
};

const HeaderBox = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
`;

const Box = styled.div`
  background-color: white;
  padding: 8px;
  margin: 3px;
  border: solid #f0f0f0 1px;
`;

const BaseBox = ({
  handleEdit,
  handleDelete,
  title,
  subtitle,
  moreInfo,
  item,
}) => {
  return (
    <Box style={{ height: '100%' }}>
      <HeaderBox>
        <div style={TitleStyleBox}>{title}</div>
        <div>
          <EditFilled style={IconStyleBox} onClick={(e) => handleEdit(item)} />
          <DeleteFilled
            onClick={(e) => handleDelete(item)}
            style={IconStyleBox}
          />
        </div>
      </HeaderBox>
      <div>{subtitle}</div>
      <div>{moreInfo}</div>
    </Box>
  );
};
export default BaseBox;
