import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  contacto: {
    marginBottom: 2,
    fontSize: 10,
  },
});

const ComprobanteObservacion = ({ data }) => {
  return (
    <>
      <View
        style={{
          flexDirection: 'row',
          border: 1,
          padding: 3,
          marginBottom: 5,
        }}
      >
        <Text style={styles.contacto}>{data.observacion}</Text>
      </View>
    </>
  );
};
export default ComprobanteObservacion;
