import React, { useState, useEffect } from 'react';

import MetaTable from './base/MetaTable';
import { chequeApi } from '../utils/api';
import CustomCellRender from './base/CustomCellRender';
import dayjs from 'dayjs';
import moment from 'moment';
import { convertDate } from '../utils/funtions';
const ChequeVencimientos = () => {
  const formatDisplay = 'DD/MM/YYYY';

  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    chequeApi
      .get(`/vencimientos/1`)
      .then((response) => {
        const data = [
          ...response.data.map((item) => {
            return {
              ...item,
              fecha: dayjs(
                new Date(moment(moment(convertDate(item.fecha)))).toISOString()
              ).format(formatDisplay),
              fecha_cobro: dayjs(
                new Date(
                  moment(moment(convertDate(item.fecha_cobro)))
                ).toISOString()
              ).format(formatDisplay),
            };
          }),
        ];

        setRows(data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
    // eslint-disable-next-line
  }, []);

  const columns = [
    {
      width: 150,
      ellipsis: true,
      title: 'Numero',
      dataIndex: 'numero',
      sorter: (a, b) => a.nombre.localeCompare(b.nombre),
      search: true,
    },
    {
      width: 150,
      ellipsis: true,
      title: 'Importe',
      dataIndex: 'importe',
      render: (text) => (
        <CustomCellRender text={text} justify={'space-between'} prefix={'$'} />
      ),
    },
    {
      width: 150,
      ellipsis: true,
      title: 'Fecha Cobro',
      dataIndex: 'fecha_cobro',
    },
    {
      width: 150,
      ellipsis: true,
      title: 'Días para vencer',
      dataIndex: 'dias',
      render: (text) => {
        if (text <= 10 && text >= 0) {
          return (
            <span style={{ fontWeight: 'bold', color: 'red' }}>{text}</span>
          );
        } else if (text <= 30 && text > 10) {
          return (
            <span style={{ fontWeight: 'bold', color: 'green' }}>{text}</span>
          );
        } else if (text < 0) {
          return (
            <span style={{ fontWeight: 'bold', color: 'black' }}>{text}</span>
          );
        } else {
          return { text };
        }
      },
    },
  ];

  return (
    <MetaTable
      dataSource={rows}
      dataColumns={columns}
      bordered={false}
      selection={false}
      loading={loading}
      // cssStyle={true}
    />
  );
};

export default ChequeVencimientos;
