import React, { useEffect, useState } from 'react';
import { comprobanteApi } from '../utils/api';

import Plot from 'react-plotly.js';

const ComprobanteFacturacion = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    comprobanteApi
      .get(`/facturacion/4`)
      .then((response) => {
        setData([
          {
            x: response.data.map((item) => `${item.mes}/${item.anio}`),
            y: response.data.map(
              (item) => item.facturas + item.debitos - item.creditos
            ),
            name: 'Facturación',
            type: 'bar',
          },
          {
            x: response.data.map((item) => `${item.mes}/${item.anio}`),
            y: response.data.map(
              (item) => item.facturasC + item.debitosC - item.creditosC
            ),
            name: 'Gastos',
            type: 'bar',
          },
        ]);

        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      <Plot
        style={{
          width: '100%',
          height: '100%',
        }}
        data={data}
        layout={{
          margin: { t: 10, r: 20, b: 30, l: 80 },
          barmode: 'group',
          autosize: true,
          yaxis: {
            tickformat: ',.0f',
          },
        }}
      />
    </div>
  );
};

export default ComprobanteFacturacion;
