import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import {
  DraggableModal,
  DraggableModalProvider,
} from 'ant-design-draggable-modal';
import { Table } from 'antd';
import { comprobanteApi, comprobanteCompraApi } from '../utils/api';
import CustomCellRender from './base/CustomCellRender';
// eslint-disable-next-line
const axios = require('axios');

const Container = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
`;

const Container2 = styled.div`
  position: absolute;
  width: 100%;
  overflow: auto;
  height: 100%;
  left: 0;
`;

const FacturasAsociadasPopup = ({
  proveedor,
  cliente,
  onCancel,
  onOk,
  itemSelected,
}) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);

  const [data, setData] = useState([]);

  useEffect(() => {
    if (proveedor) {
      comprobanteCompraApi
        .post(`/asociadoPago/${proveedor}`)
        .then((response) => {
          if (itemSelected && itemSelected.length > 0) {
            const result = response.data.map((value) => {
              if (itemSelected.some((item) => value.id === item.id)) {
                return false;
              } else {
                return value;
              }
            });

            setData(result.filter((item) => item));
          } else {
            setData(response.data);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
    // eslint-disable-next-line
  }, [proveedor]);

  useEffect(() => {
    if (cliente) {
      comprobanteApi
        .post(`/asociadoCobro/${cliente}`)
        .then((response) => {
          if (itemSelected && itemSelected.length > 0) {
            const result = response.data.map((value) => {
              if (itemSelected.some((item) => value.id === item.id)) {
                return false;
              } else {
                return value;
              }
            });

            setData(parseData(result.filter((item) => item)));
          } else {
            setData(parseData(response.data));
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
    // eslint-disable-next-line
  }, [cliente]);

  const parseData = (data) => {
    let result = [];
    data.forEach((item) => {
      if (item.forma_pago_id === 2) {
        item.cuotas.forEach((cuota) => {
          result.push({
            id: `${item.id}_${cuota.id}`,
            tipo_nombre: `Cuota ${item.tipo_nombre}`,
            fecha: cuota.fecha,
            numero: item.numero,
            total: cuota.importe,
            saldo: cuota.saldo,
            estado_nombre: cuota.estado_cobro.nombre,
            comprobante_id: cuota.comprobante_id,
            cuota_id: cuota.id,
          });
        });
      } else {
        result.push({ ...item, comprobante_id: item.id, cuota_id: null });
      }
    });

    return result;
  };

  const onSelectChange = (selectedRowKeys) => {
    const result = data.filter((item) =>
      selectedRowKeys.some((value) => value === item.id)
    );

    setSelectedRowKeys(selectedRowKeys);
    setSelectedRows(result);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  data.forEach((value) => {
    value['key'] = value.id;
  });

  const columns = [
    {
      ellipsis: true,
      title: 'Tipo',
      dataIndex: 'tipo_nombre',
    },
    {
      ellipsis: true,
      title: 'Número',
      dataIndex: 'numero',
    },
    {
      ellipsis: true,
      title: 'Fecha',
      dataIndex: 'fecha',
    },
    {
      ellipsis: true,
      title: 'Total',
      dataIndex: 'total',
      render: (text) => (
        <CustomCellRender text={text} justify={'space-between'} prefix={'$'} />
      ),
    },
    {
      ellipsis: true,
      title: 'Saldo',
      dataIndex: 'saldo',
      render: (text) => (
        <CustomCellRender text={text} justify={'space-between'} prefix={'$'} />
      ),
    },
    {
      ellipsis: true,
      title: 'Estado',
      dataIndex: 'estado_nombre',
    },
  ];

  return (
    <DraggableModalProvider>
      <DraggableModal
        visible={true}
        okText="Guardar"
        cancelText="Salir"
        onCancel={(e) => onCancel()}
        onOk={(e) => onOk(selectedRows)}
        title="Comprobantes Asociados"
        initialWidth={800}
        initialHeight={500}
      >
        <Container>
          <Container2>
            <Table
              tableLayout="auto"
              size="small"
              columns={columns}
              dataSource={data}
              pagination={false}
              rowSelection={rowSelection}
            />
          </Container2>
        </Container>
      </DraggableModal>
    </DraggableModalProvider>
  );
};

export default FacturasAsociadasPopup;
