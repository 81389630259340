import React, { useState } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { callStatus } from './utils/constants';
import { doLogin } from './redux/AuthActions';
//ANT /////////////////////////////
import { Input, Button, Spin, Alert } from 'antd';
/////////////////

const Main = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
`;

const Container = styled.div`
  flex-basis: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const InputCointainer = styled.div`
  width: 100%;
  margin-bottom: 20px;
`;
const ImageCointainer = styled.div`
  margin-bottom: 20px;
`;
// const Alert = styled(Alert)`
//   margin-top: 20px;
// `;
// const Button = styled(Button)`
//   margin-left: 8px;
//   span {
//     color: white;
//   }
// `;
const Login = ({ dispatch, auth }) => {
  const [user, setUser] = useState('');
  const [password, setPassword] = useState('');

  const handlerChangeUser = (e) => {
    setUser(e.target.value);
  };

  const handlerChangePassword = (e) => {
    setPassword(e.target.value);
  };

  const handlerSubmit = () => {
    doLogin(dispatch, { name: user, password: password });
  };

  return (
    <>
      <Spin spinning={auth.status === callStatus.IN_PROCESS}>
        <Main>
          <Container>
            <ImageCointainer>
              <img style={{ width: '100%' }} src="logo.png" alt="" />
            </ImageCointainer>
            <InputCointainer>
              <Input
                value={user}
                placeholder="Usuario"
                onChange={handlerChangeUser}
                onPressEnter={handlerSubmit}
              />
            </InputCointainer>
            <InputCointainer>
              <Input
                value={password}
                type="password"
                placeholder="Password"
                onChange={handlerChangePassword}
                onPressEnter={handlerSubmit}
              />
            </InputCointainer>

            <Button type="primary" onClick={handlerSubmit}>
              Iniciar
            </Button>

            {auth.status === callStatus.FAILED ? (
              <Alert
                message={auth.errors}
                type="error"
                showIcon
                style={{ marginTop: '20px' }}
              />
            ) : (
              false
            )}
          </Container>
        </Main>
      </Spin>
    </>
  );
};

const mapStateToProps = (state) => {
  const { auth } = state;

  return {
    auth,
  };
};
export default connect(mapStateToProps)(Login);
