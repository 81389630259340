import { createReducer } from '../utils/DataReducer';

export const condicionIvaReducer = createReducer({
  dataName: 'CONDICION_IVA',
});
export const monedaReducer = createReducer({
  dataName: 'MONEDA',
});

export const proveedorReducer = createReducer({
  dataName: 'PROVEEDOR',
});

export const clienteReducer = createReducer({
  dataName: 'CLIENTE',
});

export const clienteContactoReducer = createReducer({
  dataName: 'CLIENTE_CONTACTO',
});

export const listaReducer = createReducer({
  dataName: 'LISTA',
});

export const depositoReducer = createReducer({
  dataName: 'DEPOSITO',
});

export const productoReducer = createReducer({
  dataName: 'PRODUCTO',
});

export const servicioReducer = createReducer({
  dataName: 'SERVICIO',
});

export const productoListaPrecioReducer = createReducer({
  dataName: 'PRODUCTO_LISTA_PRECIO',
});

export const productoDepositoReducer = createReducer({
  dataName: 'PRODUCTO_DEPOSITO',
});

export const comprobanteReducer = createReducer({
  dataName: 'COMPROBANTE',
});

export const comprobanteTipoReducer = createReducer({
  dataName: 'COMPROBANTE_TIPO',
});

export const puntoVentaReducer = createReducer({
  dataName: 'PUNTO_VENTA',
});

export const alicuotaReducer = createReducer({
  dataName: 'ALICUOTA',
});

export const configuracionReducer = createReducer({
  dataName: 'CONFIGURACION',
});

export const cobroReducer = createReducer({
  dataName: 'COBRO',
});

export const chequeTipoReducer = createReducer({
  dataName: 'CHEQUE_TIPO',
});

export const chequeEstadoReducer = createReducer({
  dataName: 'CHEQUE_ESTADO',
});

export const tarjetaTipoReducer = createReducer({
  dataName: 'TARJETA_TIPO',
});

export const tarjetaEmpresaReducer = createReducer({
  dataName: 'TARJETA_EMPRESA',
});

export const retencionTipoReducer = createReducer({
  dataName: 'RETENCION_TIPO',
});

export const cobroTipoReducer = createReducer({
  dataName: 'COBRO_TIPO',
});

export const condicionVentaReducer = createReducer({
  dataName: 'CONDICION_VENTA',
});

export const chequeReducer = createReducer({
  dataName: 'CHEQUE',
});

export const comprobanteObservacionReducer = createReducer({
  dataName: 'COMPROBANTE_OBSERVACION',
});

export const comprobanteCompraReducer = createReducer({
  dataName: 'COMPROBANTE_COMPRA',
});

export const rubroReducer = createReducer({
  dataName: 'RUBRO',
});

export const pagoReducer = createReducer({
  dataName: 'PAGO',
});

export const cuotaReducer = createReducer({
  dataName: 'CUOTA',
});

export const formaPagoReducer = createReducer({
  dataName: 'FORMA_PAGO',
});
