import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  getAll,
  setPending,
  deleted,
  setDeletedPending,
  getAllDeleted,
  restore,
} from '../redux/actions/comprobanteCompraActions';
import { callStatus } from '../utils/constants';
import { Button, Space, Layout, Modal } from 'antd';
import { withRouter } from 'react-router-dom';
import {
  EditFilled,
  DeleteFilled,
  PlusCircleOutlined,
  SelectOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import styled from 'styled-components';
import RestorePopup from './RestorePopup';
import { useHistory } from 'react-router-dom';
import dayjs from 'dayjs';
import MetaTable from './base/MetaTable';
import moment from 'moment';
import { convertDate } from '../utils/funtions';
import CustomCellRender from './base/CustomCellRender';
const { confirm, error, success } = Modal;

const { Content, Header, Footer } = Layout;

// const HeaderContainer = styled.div`
//   display: flex;
//   align-items: center;
//   width: 100%;
// `;

const SelectedContainer = styled.div`
  flex: 1;
`;

const ActionsContainer = styled.div``;

const FooterContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding-top: 10px;
`;

const ComprobanteCompraList = ({
  location,
  stateData,
  comprobanteTipo,
  dispatch,
}) => {
  const [showRestore, setShowRestore] = useState(false);
  const [dataSource, setDataSource] = useState([]);

  const history = useHistory();
  const formatDisplay = 'DD/MM/YYYY';

  useEffect(() => {
    getAll(dispatch);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (stateData.list.status === callStatus.LIST_SUCCESS) {
      const data = [
        ...stateData.list.data.map((item) => {
          return {
            ...item,
            fecha: dayjs(
              new Date(moment(moment(convertDate(item.fecha)))).toISOString()
            ).format(formatDisplay),
            fecha_carga: dayjs(
              new Date(
                moment(moment(convertDate(item.fecha_carga)))
              ).toISOString()
            ).format(formatDisplay),
          };
        }),
      ];

      setDataSource(data);
    }

    // eslint-disable-next-line
  }, [stateData]);

  // const handleEdit = (data) => {
  //   setRowEdit(data);
  //   setShowEdit(true);
  // };

  const handleRestore = () => {
    setShowRestore(true);
    getAllDeleted(dispatch);
  };

  const handleRestoreCancel = () => {
    setShowRestore(false);
    dispatch(setDeletedPending());
  };

  const handleRestoreOk = (data) => {
    dispatch(restore({ data: data }));
    setShowRestore(false);
  };

  const handleDelete = (data) => {
    dispatch(deleted(data.id));
  };

  // const handleHideEditor = () => {
  //   setShowEdit(false);
  //   setRowEdit(null);
  //   dispatch(setPending());
  // };

  const handleConfirm = (item) => {
    if (item.tipo_id > 0 && item.estado_afip_id === 2) {
      error({
        title: 'Este comprobante no puede eliminarse, debe Anularlo',
        onOk() {},
      });
    } else {
      confirm({
        title: 'Esta a punto de eliminar un registro, desea continuar?',
        icon: <ExclamationCircleOutlined />,
        okText: 'Si',
        cancelText: 'No',
        autoFocusButton: 'cancel',
        onOk() {
          handleDelete(item);
        },
        onCancel() {},
      });
    }
  };

  const handleEditor = (data) => {
    history.push(`/compras/compra/${data.id}`);
  };

  const handleAdd = () => {
    history.push(`/compras/compra/0`);
  };

  const restoreColumns = [
    {
      ellipsis: true,
      title: 'Número',
      dataIndex: 'numero',
    },
  ];

  const columns = [
    {
      title: '',
      key: 'action',
      render: (text, record) => (
        <Space size="middle">
          <EditFilled onClick={(e) => handleEditor(record)} />
          <DeleteFilled onClick={(e) => handleConfirm(record)} />
        </Space>
      ),
      width: 90,
      ellipsis: true,
    },

    {
      width: 150,
      ellipsis: true,
      title: 'Tipo',
      dataIndex: 'tipo_nombre',
      onFilter: (value, record) => {
        return record.tipo_id === value;
      },
      sorter: (a, b) => a.tipo_nombre.localeCompare(b.tipo_nombre),
      filterMultiple: true,
      filters: comprobanteTipo.list.data.map((value) => {
        value['value'] = value.id;
        value['text'] = value.letra
          ? value.nombre + ' ' + value.letra
          : value.nombre;
        return value;
      }),
    },
    {
      width: 100,
      ellipsis: true,
      title: 'Fecha',
      dataIndex: 'fecha',
    },
    {
      width: 100,
      ellipsis: true,
      title: 'Fecha carga',
      dataIndex: 'fecha_carga',
    },
    {
      width: 200,
      ellipsis: true,
      title: 'Número',
      dataIndex: 'numero',
      sorter: (a, b) => a.numero.localeCompare(b.numero),
      search: true,
    },
    {
      width: 300,
      ellipsis: true,
      title: 'Proveedor',
      dataIndex: 'proveedor_nombre',
      sorter: (a, b) => a.cliente_nombre.localeCompare(b.cliente_nombre),
      search: true,
    },
    {
      width: 200,
      ellipsis: true,
      title: 'Total',
      dataIndex: 'total',
      render: (text) => (
        <CustomCellRender text={text} justify={'space-between'} prefix={'$'} />
      ),
    },
  ];

  const handleShowMessage = (message, type, handler) => {
    switch (type) {
      case 'success':
        success({
          title: message,
          onOk() {
            handler();
          },
        });
        break;
      case 'error':
        error({
          title: message,
          onOk() {
            handler();
          },
        });
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (stateData.object.status === callStatus.OBJECT_SAVE_SUCCESS) {
      handleShowMessage(
        'El Comprobante se actualizó con éxito',
        'success',
        () => {}
      );
      dispatch(setPending());
    }
    if (stateData.object.status === callStatus.OBJECT_FAILED) {
      handleShowMessage(stateData.object.error, 'error', () => {});
    }

    // eslint-disable-next-line
  }, [stateData.object]);

  return (
    <Layout style={{ height: '100%' }}>
      <Header></Header>
      <Content>
        <MetaTable
          dataSource={dataSource}
          dataColumns={columns}
          bordered={false}
          selection={false}
          loading={
            stateData.list.status === callStatus.LIST_IN_PROCESS ||
            stateData.object.status === callStatus.LIST_IN_PROCESS
          }
          pagination={true}
        />
      </Content>
      <Footer>
        <FooterContainer>
          <SelectedContainer></SelectedContainer>
          <ActionsContainer>
            <Button
              type="primary"
              icon={<PlusCircleOutlined />}
              size="small"
              // onClick={(e) => handleEdit({})}
              onClick={(e) => handleAdd()}
            >
              Agregar
            </Button>
            <Button
              type="primary"
              icon={<SelectOutlined />}
              size="small"
              style={{ marginLeft: '3px' }}
              onClick={(e) => handleRestore()}
            >
              Recuperar
            </Button>
          </ActionsContainer>
        </FooterContainer>
      </Footer>

      {showRestore &&
        stateData.deleted.status === callStatus.LIST_DELETED_SUCCESS && (
          <RestorePopup
            columns={restoreColumns}
            data={stateData.deleted.data}
            onCancel={handleRestoreCancel}
            onOk={handleRestoreOk}
          />
        )}
    </Layout>
  );
};

const mapStateToProps = (state) => {
  const { comprobanteCompra, comprobanteTipo } = state;
  const stateData = comprobanteCompra;
  return {
    stateData,
    comprobanteTipo,
  };
};
export default connect(mapStateToProps)(withRouter(ComprobanteCompraList));
