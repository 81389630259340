import React, { useEffect, useState } from 'react';
import { Modal, Card, Space, Layout } from 'antd';
import {
  ExclamationCircleOutlined,
  PlusCircleOutlined,
  InteractionOutlined,
  EditFilled,
  DeleteFilled,
} from '@ant-design/icons';
import styled from 'styled-components';
import MetaTable from '../base/MetaTable';
import TransferenciaEditor from './TransferenciaEditor';
import dayjs from 'dayjs';
import moment from 'moment';
import cloneDeep from 'lodash/cloneDeep';
import { convertDate } from '../../utils/funtions';
import { Content } from 'antd/lib/layout/layout';

const { confirm } = Modal;

const TitleCard = styled.div`
  display: flex;
  align-items: center;
`;

const TransferenciaList = ({ id, onChange, dataSource }) => {
  const formatDisplay = 'DD/MM/YYYY';

  const [columns, setColumns] = useState([
    {
      title: '',
      key: 'action',
      dataIndex: 'action',
      render: (text, record) => (
        <Space size="middle">
          <EditFilled onClick={() => handleEdit(record)} />
          <DeleteFilled onClick={() => handleConfirmDelete(record)} />
        </Space>
      ),
      width: 70,
    },

    {
      width: 100,
      title: 'Fecha',
      dataIndex: 'fecha',
    },
    {
      width: 150,
      title: 'Total',
      dataIndex: 'total',
    },
    {
      width: 400,
      title: 'Detalle',
      dataIndex: 'detalle',
      ellipsis: true,
    },
  ]);
  const [rows, setRows] = useState([]);
  const [rowEdit, setRowEdit] = useState(null);
  const [showEdit, setShowEdit] = useState(null);

  const handleSave = (data) => {
    if (rowEdit) {
      editRow(data);
    } else {
      addRow(data);
    }

    setShowEdit(false);
    setRowEdit(null);
  };

  const addRow = (data) => {
    data.fecha = dayjs(new Date(data.fecha).toISOString()).format(
      formatDisplay
    );

    data.key = rows.length ? rows[rows.length - 1].key + 1 : 0;

    const newArray = [...rows, data];

    setRows(newArray);
  };

  const editRow = (data) => {
    const newArray = [
      ...rows.map((item) => {
        if (item.key === data.key) {
          return {
            ...data,
            fecha: dayjs(new Date(data.fecha).toISOString()).format(
              formatDisplay
            ),
          };
        } else {
          return item;
        }
      }),
    ];
    setRows(newArray);
  };

  const handleEdit = (value) => {
    let data = cloneDeep(value);

    if (data) {
      data.fecha = moment(moment(convertDate(data.fecha)));
    }

    setRowEdit(data);
    setShowEdit(true);
  };

  const handleDelete = (data) => {
    setRows((prev) => {
      return [...prev.filter((item) => item.key !== data.key)];
    });
  };

  const handleHideEditor = () => {
    setShowEdit(false);
    setRowEdit(null);
    // dispatch(setPending());
  };

  const handleConfirmDelete = (item) => {
    confirm({
      title: 'Esta a punto de eliminar un registro, desea continuar?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Si',
      cancelText: 'No',
      autoFocusButton: 'cancel',
      onOk() {
        handleDelete(item);
      },
      onCancel() {},
    });
  };

  useEffect(() => {
    onChange(rows);
    // eslint-disable-next-line
  }, [rows]);

  useEffect(() => {
    if (dataSource && dataSource.length > 0) {
      setRows(dataSource);
    }
  }, [dataSource]);

  useEffect(() => {
    if (id) {
      setColumns((prev) => {
        return prev.filter((item) => item.dataIndex !== 'action');
      });
    }
  }, [id]);

  return (
    <>
      <Card
        extra={
          !id && (
            <PlusCircleOutlined
              style={{ fontSize: '18px' }}
              onClick={() => handleEdit(null)}
            />
          )
        }
        title={
          <TitleCard>
            <InteractionOutlined
              style={{ fontSize: '22px', color: '#4f43ba' }}
            />

            <span style={{ marginLeft: '3px' }}>Transferencias</span>
          </TitleCard>
        }
        style={{ marginBottom: '10px' }}
        size="small"
        bodyStyle={rows.length === 0 ? { padding: 0 } : {}}
      >
        <Layout style={{ height: '100%' }}>
          <Content>
            {rows.length > 0 && (
              <MetaTable
                dataSource={rows}
                dataColumns={columns}
                bordered={false}
                selection={false}
                cssStyle={true}
              />
            )}
          </Content>
        </Layout>
      </Card>

      {showEdit && (
        <TransferenciaEditor
          dataSource={rowEdit}
          onCancel={handleHideEditor}
          onCreate={handleSave}
          visible={true}
        />
      )}
    </>
  );
};

export default TransferenciaList;
