import React, { useState, useEffect } from 'react';
import DepositoEditor from './DepositoEditor';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import {
  getAll,
  setPending,
  deleted,
  setDeletedPending,
  getAllDeleted,
  restore,
} from '../redux/actions/depositoActions';
import { callStatus } from '../utils/constants';
import { Button, Space, Layout, Modal } from 'antd';
import { withRouter } from 'react-router-dom';
import {
  EditFilled,
  DeleteFilled,
  PlusCircleOutlined,
  SelectOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import styled from 'styled-components';
import RestorePopup from './RestorePopup';
import MetaTable from './base/MetaTable';

const { confirm } = Modal;

const { Content, Header, Footer } = Layout;

const SelectedContainer = styled.div`
  flex: 1;
`;

const ActionsContainer = styled.div``;

const FooterContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding-top: 10px;
`;

const DepositoList = ({ location, stateData, dispatch }) => {
  const [showRestore, setShowRestore] = useState(false);

  const [showEdit, setShowEdit] = useState(false);
  const [rowEdit, setRowEdit] = useState(null);

  const history = useHistory();

  useEffect(() => {
    getAll(dispatch);
    // eslint-disable-next-line
  }, []);

  const handleEdit = (data) => {
    setRowEdit(data);
    setShowEdit(true);
  };

  const handleRestore = () => {
    setShowRestore(true);
    getAllDeleted(dispatch);
  };

  const handleRestoreCancel = () => {
    setShowRestore(false);
    dispatch(setDeletedPending());
  };

  const handleRestoreOk = (data) => {
    dispatch(restore({ data: data }));
    setShowRestore(false);
  };

  const handleDelete = (data) => {
    dispatch(deleted(data.id));
  };

  const handleProductos = (data) => {
    history.push(`/depositos/deposito-productos/${data.id}`);
  };
  const handleHideEditor = () => {
    setShowEdit(false);
    setRowEdit(null);
    dispatch(setPending());
  };

  const handleConfirm = (item) => {
    confirm({
      title: 'Esta a punto de eliminar un registro, desea continuar?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Si',
      cancelText: 'No',
      autoFocusButton: 'cancel',
      onOk() {
        handleDelete(item);
      },
      onCancel() {},
    });
  };

  const restoreColumns = [
    {
      ellipsis: true,
      title: 'Nombre',
      dataIndex: 'nombre',
    },
    {
      ellipsis: true,
      title: 'Apellido',
      dataIndex: 'apellido',
    },
  ];

  const columns = [
    {
      title: '',
      key: 'action',
      render: (text, record) => (
        <Space size="middle">
          <EditFilled onClick={(e) => handleEdit(record)} />
          <DeleteFilled onClick={(e) => handleConfirm(record)} />
        </Space>
      ),
      width: 60,
      ellipsis: true,
    },
    {
      width: 200,
      ellipsis: true,
      title: 'Nombre',
      dataIndex: 'nombre',
    },

    {
      title: '',
      key: 'action',
      render: (text, record) => (
        <Space size="middle">
          <Button onClick={(e) => handleProductos(record)}>Productos</Button>
        </Space>
      ),
      width: 200,
      ellipsis: true,
    },
  ];

  return (
    <Layout style={{ height: '100%' }}>
      <Header></Header>
      <Content>
        <MetaTable
          dataSource={stateData.list.data}
          dataColumns={columns}
          bordered={false}
          selection={false}
          loading={stateData.list.status === callStatus.LIST_IN_PROCESS}
        />
      </Content>
      <Footer>
        <FooterContainer>
          <SelectedContainer>
            {/* {selectedRowKeys.length > 0
                ? `${selectedRowKeys.length} filas seleccionadas`
                : 'No hay files seleccionadas'} */}
          </SelectedContainer>
          <ActionsContainer>
            <Button
              type="primary"
              icon={<PlusCircleOutlined />}
              size="small"
              onClick={(e) => handleEdit({})}
            >
              Agregar
            </Button>
            <Button
              type="primary"
              icon={<SelectOutlined />}
              size="small"
              style={{ marginLeft: '3px' }}
              onClick={(e) => handleRestore()}
            >
              Recuperar
            </Button>
            {/* <Button
              type="primary"
              icon={<DownloadOutlined />}
              size="small"
              style={{ marginLeft: '3px' }}
            >
              Exportar
            </Button> */}
          </ActionsContainer>
        </FooterContainer>
      </Footer>
      {showEdit ? (
        <DepositoEditor
          id={rowEdit.id}
          onCancel={handleHideEditor}
          // onCreate={handleSaveEditor}
          visible={true}
        />
      ) : (
        false
      )}

      {showRestore &&
      stateData.deleted.status === callStatus.LIST_DELETED_SUCCESS ? (
        <RestorePopup
          columns={restoreColumns}
          data={stateData.deleted.data}
          onCancel={handleRestoreCancel}
          onOk={handleRestoreOk}
        />
      ) : (
        false
      )}
    </Layout>
  );
};

const mapStateToProps = (state) => {
  const { deposito } = state;
  const stateData = deposito;
  return {
    stateData,
  };
};
export default connect(mapStateToProps)(withRouter(DepositoList));
