import { LogoutOutlined, MenuOutlined } from '@ant-design/icons';
import Icon from '@mdi/react';
import { Button, Drawer, Layout, Menu } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import styled, { createGlobalStyle } from 'styled-components';
import packageJson from '../package.json';
import { doLogout } from './redux/AuthActions';
import routes from './routes';

import {
  mdiAccount,
  mdiAccountLock,
  mdiAccountTie,
  mdiAccountWrench,
  mdiCashClock,
  mdiCashMinus,
  mdiCashPlus,
  mdiCog,
  mdiEngine,
  mdiFinance,
  mdiFormatListBulletedSquare,
  mdiShieldAccountOutline,
  mdiStore,
  mdiTagMultipleOutline,
  mdiTextBoxMinusOutline,
  mdiTextBoxPlusOutline,
  mdiViewHeadline,
} from '@mdi/js';
import { hasPermiso } from './utils/funtions';

// const { SubMenu } = Menu;
const { Header, Content } = Layout;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
`;

const HeaderTitle = styled.div`
  flex: 1;
  margin-left: 10px;
`;

const LayoutStyle = createGlobalStyle`

.ant-layout-header{
  background:#f0f2f5;
  padding: 0;
  height: auto;
  line-height: 0;
}

.ant-layout-content{
  background:#f0f2f5;
  padding: 0;
}

.ant-layout-footer{
  background:#f0f2f5;
  padding: 0 10px 10px 0;
  height: auto;
}

.ant-input-affix-wrapper {
  input.ant-input {
    text-align: right !important;
  }
}
.anticon.ant-input-clear-icon-hidden, .ant-input-clear-icon-hidden {
  visibility: visible;
}
`;

const HeaderIcons = styled.div``;
const Navbar = ({ dispatch }) => {
  const [visible, setVisible] = React.useState(false);

  const logout = () => {
    doLogout(dispatch);
  };
  const onClose = () => {
    setVisible(false);
  };

  return (
    <>
      {/* <ListStyle /> */}
      <LayoutStyle />

      <Drawer
        title={null}
        extra={
          <div style={{ width: '210px', paddingLeft: '10px' }}>
            <img style={{ width: '100%' }} src={'logo.png'} alt="Logo" />
          </div>
        }
        placement="left"
        closable={true}
        onClose={(e) => onClose()}
        visible={visible}
        getContainer={false}
        style={{ position: 'absolute' }}
        bodyStyle={{ padding: 0 }}
        width={250}
        headerStyle={{ padding: '10px' }}
      >
        <Menu mode="inline">
          {hasPermiso(500) && (
            <Menu.SubMenu key="submenu-stock" title="Stock">
              {hasPermiso(510) && (
                <Menu.Item
                  key="listas"
                  icon={<Icon path={mdiFormatListBulletedSquare} size={1} />}
                >
                  <Link to="/listas" onClick={(e) => onClose()}>
                    Listas
                  </Link>
                </Menu.Item>
              )}
              {hasPermiso(520) && (
                <Menu.Item
                  key="depositos"
                  icon={<Icon path={mdiStore} size={1} />}
                >
                  <Link to="/depositos" onClick={(e) => onClose()}>
                    Depósitos
                  </Link>
                </Menu.Item>
              )}
              {hasPermiso(530) && (
                <Menu.Item
                  key="productos"
                  icon={<Icon path={mdiEngine} size={1} />}
                >
                  <Link to="/productos" onClick={(e) => onClose()}>
                    Productos
                  </Link>
                </Menu.Item>
              )}
              {hasPermiso(540) && (
                <Menu.Item
                  key="servicios"
                  icon={<Icon path={mdiAccountWrench} size={1} />}
                >
                  <Link to="/servicios" onClick={(e) => onClose()}>
                    Servicios
                  </Link>
                </Menu.Item>
              )}
            </Menu.SubMenu>
          )}
          {hasPermiso(300) && (
            <Menu.SubMenu key="submenu-ventas" title="Ventas">
              {hasPermiso(310) && (
                <Menu.Item
                  key="clientes"
                  icon={<Icon path={mdiAccount} size={1} />}
                >
                  <Link to="/clientes" onClick={(e) => onClose()}>
                    Clientes
                  </Link>
                </Menu.Item>
              )}
              {hasPermiso(320) && (
                <Menu.Item
                  key="comprobantes"
                  icon={<Icon path={mdiTextBoxPlusOutline} size={1} />}
                >
                  <Link to="/comprobantes" onClick={(e) => onClose()}>
                    Comp Ventas
                  </Link>
                </Menu.Item>
              )}
              {hasPermiso(330) && (
                <Menu.Item
                  key="cobros"
                  icon={<Icon path={mdiCashPlus} size={1} />}
                >
                  <Link to="/cobros" onClick={(e) => onClose()}>
                    Cobros
                  </Link>
                </Menu.Item>
              )}
            </Menu.SubMenu>
          )}
          {hasPermiso(400) && (
            <Menu.SubMenu key="submenu-compras" title="Compras">
              {hasPermiso(410) && (
                <Menu.Item
                  key="proveedores"
                  icon={<Icon path={mdiAccountTie} size={1} />}
                >
                  <Link to="/proveedores" onClick={(e) => onClose()}>
                    Proveedores
                  </Link>
                </Menu.Item>
              )}
              {hasPermiso(420) && (
                <Menu.Item
                  key="rubros"
                  icon={<Icon path={mdiTagMultipleOutline} size={1} />}
                >
                  <Link to="/rubros" onClick={(e) => onClose()}>
                    Rubros
                  </Link>
                </Menu.Item>
              )}
              {hasPermiso(430) && (
                <Menu.Item
                  key="compras"
                  icon={<Icon path={mdiTextBoxMinusOutline} size={1} />}
                >
                  <Link to="/compras" onClick={(e) => onClose()}>
                    Comp Compras
                  </Link>
                </Menu.Item>
              )}
              {hasPermiso(440) && (
                <Menu.Item
                  key="pagos"
                  icon={<Icon path={mdiCashMinus} size={1} />}
                >
                  <Link to="/pagos" onClick={(e) => onClose()}>
                    Pagos
                  </Link>
                </Menu.Item>
              )}
            </Menu.SubMenu>
          )}
          {hasPermiso(600) && (
            <Menu.Item
              key="cheques"
              icon={<Icon path={mdiCashClock} size={1} />}
            >
              <Link to="/cheques" onClick={(e) => onClose()}>
                Cheques
              </Link>
            </Menu.Item>
          )}
          {hasPermiso(700) && (
            <Menu.Item key="1" icon={<Icon path={mdiViewHeadline} size={1} />}>
              <Link to="" onClick={(e) => onClose()}>
                Informes
              </Link>
            </Menu.Item>
          )}
          {hasPermiso(800) && (
            <Menu.Item
              key="estadisticas"
              icon={<Icon path={mdiFinance} size={1} />}
            >
              <Link to="/estadisticas" onClick={(e) => onClose()}>
                Estadísticas
              </Link>
            </Menu.Item>
          )}
          <Menu.Divider />
          {hasPermiso(100) && (
            <Menu.SubMenu key="submenu-seguridad" title="Seguridad">
              {hasPermiso(110) && (
                <Menu.Item
                  key="usuarios"
                  icon={<Icon path={mdiAccountLock} size={1} />}
                >
                  <Link to="#" onClick={(e) => onClose()}>
                    Usuarios
                  </Link>
                </Menu.Item>
              )}
              {hasPermiso(120) && (
                <Menu.Item
                  key="roles"
                  icon={<Icon path={mdiShieldAccountOutline} size={1} />}
                >
                  <Link to="#" onClick={(e) => onClose()}>
                    Roles
                  </Link>
                </Menu.Item>
              )}
            </Menu.SubMenu>
          )}
          {hasPermiso(800) && (
            <Menu.Item
              key="configuracion"
              icon={<Icon path={mdiCog} size={1} />}
            >
              <Link to="#" onClick={(e) => onClose()}>
                Configuración
              </Link>
            </Menu.Item>
          )}
        </Menu>

        <div
          style={{
            width: '100%',
            position: 'absolute',
            bottom: 0,
            padding: '5px',
          }}
        >
          version: {packageJson.version}
        </div>
      </Drawer>
      <div style={{ height: '100vh' }}>
        <Layout style={{ height: '100%' }}>
          <Header>
            <HeaderContainer className="site-layout-sub-header-background">
              <Button
                type="primary"
                icon={<MenuOutlined />}
                size="small"
                onClick={(e) => setVisible(!visible)}
              />

              <HeaderTitle>Gestión Comercial</HeaderTitle>
              <HeaderIcons>
                <LogoutOutlined onClick={() => logout()} />
              </HeaderIcons>
            </HeaderContainer>
          </Header>
          <Content style={{ margin: '15px' }}>{routes}</Content>
        </Layout>
      </div>
    </>
  );
};
export default connect()(Navbar);
