import React, { useState } from 'react';
import styled from 'styled-components';

import {
  DraggableModal,
  DraggableModalProvider,
} from 'ant-design-draggable-modal';
import { Table } from 'antd';

const Container = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
`;

const Container2 = styled.div`
  position: absolute;
  width: 100%;
  overflow: auto;
  height: 100%;
  left: 0;
`;

const RestorePopoup = ({ onCancel, columns, data, onOk }) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const onSelectChange = (selectedRowKeys) => {
    setSelectedRowKeys(selectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  data.forEach((value) => {
    value['key'] = value.id;
  });

  return (
    <DraggableModalProvider>
      <DraggableModal
        visible={true}
        okText="Guardar"
        cancelText="Salir"
        onCancel={(e) => onCancel()}
        onOk={(e) => onOk(selectedRowKeys)}
        title="Restaurar"
        initialWidth={500}
        initialHeight={500}
      >
        <Container>
          <Container2>
            <Table
              tableLayout="auto"
              size="small"
              columns={columns}
              dataSource={data}
              pagination={false}
              rowSelection={rowSelection}
            />
          </Container2>
        </Container>
      </DraggableModal>
    </DraggableModalProvider>
  );
};

export default RestorePopoup;
