import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Modal, Form, Input, Row, Col, Select } from 'antd';
import { getAll as listas } from '../redux/actions/listaActions';

const ProductoListaPrecioSelector = ({
  dispatch,
  visible,
  onCancel,
  onOk,
  lista,
  alicuota,
  alicuotaDefault,
}) => {
  const [form] = Form.useForm();
  const [listaList, setListaList] = useState([]);

  const onCreate = (data) => {
    onOk(data);
  };

  useEffect(() => {
    listas(dispatch);
    if (alicuotaDefault > 0) {
      form.setFieldsValue({
        alicuota_id: alicuotaDefault,
      });
    }

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setListaList(lista.list.data);
    // eslint-disable-next-line
  }, [lista]);

  return (
    <>
      <Modal
        centered
        maskClosable={false}
        visible={visible}
        title="Agregar Productos a Lista de Precios"
        okText="Guardar"
        cancelText="Salir"
        onCancel={onCancel}
        onOk={() => {
          form
            .validateFields()
            .then((values) => {
              onCreate(values);
            })
            .catch((info) => {
              console.log('Validate Failed:', info);
            });
        }}
      >
        <Form form={form} layout="vertical" name="form_in_modal">
          <Row gutter={[8, 0]}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Form.Item name="lista_id" label="Lista">
                <Select>
                  {listaList.map((item) => (
                    <Select.Option key={item.id} value={item.id}>
                      {item.nombre}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Form.Item
                name="ganancia"
                label="Ganancia"
                rules={[
                  {
                    required: true,
                    message: 'Requerido',
                  },
                ]}
              >
                <Input type="number" />
              </Form.Item>
            </Col>

            {/* <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Form.Item
                name="iva"
                label="IVA"
                rules={[
                  {
                    required: true,
                    message: 'Requerido',
                  },
                ]}
              >
                <Input type="number" />
              </Form.Item>
            </Col> */}

            <Col xs={24} sm={12} md={12} lg={12} xl={12}>
              <Form.Item
                name="alicuota_id"
                label="Alicuota Iva"
                rules={[
                  {
                    required: true,
                    message: 'Requerido',
                  },
                ]}
              >
                <Select>
                  {alicuota.list.data.map((item) => (
                    <Select.Option key={item.id} value={item.id}>
                      {item.nombre}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            {alicuotaDefault < 0 && (
              <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                <div style={{ padding: '5px', color: 'red' }}>
                  Los elementos seleccionados no poseen misma Alicuota IVA
                </div>
              </Col>
            )}
          </Row>
        </Form>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => {
  const { lista, alicuota } = state;
  return {
    lista,
    alicuota,
  };
};
export default connect(mapStateToProps)(ProductoListaPrecioSelector);
