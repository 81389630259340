import { productoListaPrecioApi } from '../../utils/api';
import {
  getObjectDataAction,
  createObjectDataPending,
  getListDataAction,
  saveObjectDataAction,
  deleteObjectDataAction,
  getListDeletedDataAction,
  createListDataDeletedPending,
  restoreObjectDataAction,
  getListByParentDataAction,
} from '../../utils/DataAction';

export const get = (id) =>
  getObjectDataAction({
    dataName: 'PRODUCTO_LISTA_PRECIO',
    api: productoListaPrecioApi,
    relUrl: id,
  });

export const getAll = getListDataAction({
  dataName: 'PRODUCTO_LISTA_PRECIO',
  api: productoListaPrecioApi,
  relUrl: '',
});

export const getAllByParent = (
  idLista,
  idDeposito = undefined,
  search = undefined
) =>
  getListByParentDataAction({
    dataName: 'PRODUCTO_LISTA_PRECIO',
    api: productoListaPrecioApi,
    relUrl: `indexByParent/${idLista}/${idDeposito}/${search}`,
  });

export const save = (data) =>
  saveObjectDataAction({
    dataName: 'PRODUCTO_LISTA_PRECIO',
    api: productoListaPrecioApi,
    relUrl: '',
    data: data,
  });

export const deleted = (id) =>
  deleteObjectDataAction({
    dataName: 'PRODUCTO_LISTA_PRECIO',
    api: productoListaPrecioApi,
    relUrl: id,
  });

export const getAllDeleted = getListDeletedDataAction({
  dataName: 'PRODUCTO_LISTA_PRECIO',
  api: productoListaPrecioApi,
  relUrl: 'restore',
});

export const restore = (data) =>
  restoreObjectDataAction({
    dataName: 'PRODUCTO_LISTA_PRECIO',
    api: productoListaPrecioApi,
    relUrl: 'restore',
    data: data,
  });

export const setPending = createObjectDataPending('PRODUCTO_LISTA_PRECIO');
export const setDeletedPending = createListDataDeletedPending(
  'PRODUCTO_LISTA_PRECIO'
);

export const addProductos = (data) => {
  return (dispatch) => {
    return productoListaPrecioApi
      .post('addProductos', data)
      .then((response) => {
        console.log('Exito');
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

export const search = (id, value) =>
  getObjectDataAction({
    dataName: 'PRODUCTO_LISTA_PRECIO',
    api: productoListaPrecioApi,
    relUrl: `search/${id}/${value}`,
  });
