import React from 'react';
import { Link } from 'react-router-dom';

const Breadcrumbs = ({ crumbs }) => {
  if (crumbs.length <= 1) {
    return null;
  }

  return (
    <div style={{ marginBottom: '10px' }}>
      {crumbs.map(({ name, path }, key) =>
        key + 1 === crumbs.length ? (
          <span key={key} style={{ fontWeight: 'bold' }}>
            {' '}
            {name}
          </span>
        ) : (
          <Link key={key} to={path}>
            <span> {name} /</span>
          </Link>
        )
      )}
    </div>
  );
};

export default Breadcrumbs;
