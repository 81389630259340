import React from 'react';
const CustomCellRender = ({ prefix, text, justify }) => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: justify,
        width: '100%',
      }}
    >
      <div>{prefix}</div>
      <div>{text}</div>
    </div>
  );
};
export default CustomCellRender;
