import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import {
  DraggableModal,
  DraggableModalProvider,
} from 'ant-design-draggable-modal';
import { Table } from 'antd';
import { comprobanteApi, comprobanteCompraApi } from '../utils/api';
import dayjs from 'dayjs';
import moment from 'moment';
import { convertDate } from '../utils/funtions';
// eslint-disable-next-line
const axios = require('axios');

const Container = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
`;

const Container2 = styled.div`
  position: absolute;
  width: 100%;
  overflow: auto;
  height: 100%;
  left: 0;
`;

const AsociadosPopoup = ({
  proveedor,
  cliente,
  tipo,
  onCancel,
  onOk,
  itemSelected,
}) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [data, setData] = useState([]);
  const formatDisplay = 'DD/MM/YYYY';

  const getTipo = (value) => {
    switch (value) {
      case -6:
      case -7:
        return [-5];
      case -3:
        return [-2];
      case -5:
      case 1:
      case 6:
      case 11:
        return [-2, -3];
      case 2:
      case 3:
        return [1];
      case 7:
      case 8:
        return [6];
      case 12:
      case 13:
        return [11];
      default:
        return null;
    }
  };

  useEffect(() => {
    if (proveedor && tipo) {
      comprobanteCompraApi
        .post(`/asociado/${proveedor}`, { data: getTipo(tipo) })
        .then((response) => {
          if (itemSelected && itemSelected.length > 0) {
            const result = response.data.map((value) => {
              if (itemSelected.some((item) => value.id === item.asociado_id)) {
                return false;
              } else {
                value.fecha = dayjs(
                  new Date(
                    moment(moment(convertDate(value.fecha)))
                  ).toISOString()
                ).format(formatDisplay);
                return value;
              }
            });

            setData(result.filter((item) => item));
          } else {
            const result = response.data.map((value) => {
              value.fecha = dayjs(
                new Date(moment(moment(convertDate(value.fecha)))).toISOString()
              ).format(formatDisplay);
              return value;
            });

            setData(result);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }

    // eslint-disable-next-line
  }, [proveedor, tipo]);

  useEffect(() => {
    if (cliente && tipo) {
      comprobanteApi
        .post(`/asociado/${cliente}`, { data: getTipo(tipo) })
        .then((response) => {
          if (itemSelected && itemSelected.length > 0) {
            const result = response.data.map((value) => {
              if (itemSelected.some((item) => value.id === item.asociado_id)) {
                return false;
              } else {
                value.fecha = dayjs(
                  new Date(
                    moment(moment(convertDate(value.fecha)))
                  ).toISOString()
                ).format(formatDisplay);
                return value;
              }
            });

            setData(result.filter((item) => item));
          } else {
            const result = response.data.map((value) => {
              value.fecha = dayjs(
                new Date(moment(moment(convertDate(value.fecha)))).toISOString()
              ).format(formatDisplay);
              return value;
            });

            setData(result);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
    // eslint-disable-next-line
  }, [cliente, tipo]);

  const onSelectChange = (selectedRowKeys) => {
    const result = data.filter((item) =>
      selectedRowKeys.some((value) => value === item.id)
    );

    setSelectedRowKeys(selectedRowKeys);
    setSelectedRows(result);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  data.forEach((value) => {
    value['key'] = value.id;
  });

  const columns = [
    {
      ellipsis: true,
      title: 'Tipo',
      dataIndex: 'tipo_nombre',
    },
    {
      ellipsis: true,
      title: 'Fecha',
      dataIndex: 'fecha',
    },
    {
      ellipsis: true,
      title: 'Número',
      dataIndex: 'numero',
    },
    {
      ellipsis: true,
      title: 'Total',
      dataIndex: 'total',
    },
  ];

  return (
    <DraggableModalProvider>
      <DraggableModal
        visible={true}
        okText="Guardar"
        cancelText="Salir"
        onCancel={(e) => onCancel()}
        onOk={(e) => onOk(selectedRows)}
        title="Comprobantes Asociados"
        initialWidth={600}
        initialHeight={500}
      >
        <Container>
          <Container2>
            <Table
              tableLayout="auto"
              size="small"
              columns={columns}
              dataSource={data}
              pagination={true}
              // rowSelection={rowSelection}
              rowSelection={{
                type: 'checkbox',
                // tipo === -6 || tipo === 3 || tipo === 8
                //   ? 'radio'
                //   : 'checkbox',
                ...rowSelection,
              }}
            />
          </Container2>
        </Container>
      </DraggableModal>
    </DraggableModalProvider>
  );
};

export default AsociadosPopoup;
